import React, { useEffect } from "react";
import Nav from "../components/Nav";
import SideNav from "../components/SideNav";
import { useSideNav } from "../context/StateNavContext";
import Dropdown from "../components/Dropdown";
import PropTypes from "prop-types";
import useDropdownMenuItems from "../hooks/useMenuItemsSections";
import GettingStartedPageNav from "../components/GettingStarted/OnPageNav";
import { SubPageBody } from "../components/GettingStarted/SubPageBody";
import Breadcrumb from "../components/Breadcrumb/Breadcrumb";
import { useLocation } from "react-router-dom";
import { capitalizeFirstLetter } from "../helpers/strings";
import steps from "../constants/gettingStarted/gettingStartedSteps";
import NavCard from "../components/NavCard";

const SubPage = ({ apis, pageName, sections }) => {
  const menuItems = useDropdownMenuItems(sections);
  const { sideNavVis, toggleSideNav } = useSideNav();
  const closeNav = () => {
    toggleSideNav();
  };
  const location = useLocation();
  const showNavCards = decodeURIComponent(location.pathname).includes(
    "getting started"
  );

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  const currentIndex = steps.findIndex(
    (step) => step.title.toLowerCase() === pageName.toLowerCase()
  );
  const previousStep =
    currentIndex > 0 ? steps[currentIndex - 1].title : "getting started";
  const nextStep =
    currentIndex < steps.length - 1 ? steps[currentIndex + 1].title : null;

  return (
    <div>
      <Nav
        pageName={pageName}
        showHamburgerMenu={true}
        className="absolute z-40"
      />
      <div className="flex 2xl:justify-between">
        <SideNav routes={apis} />
        <div className="w-[100%] max-w-[1000px]">
          {sideNavVis && (
            <div
              onClick={closeNav}
              className="bg-grey-out-of-focus fixed inset-0 z-10 w-screen h-screen"
            />
          )}
          <div className="px-[24px]">
            <Breadcrumb paths={location.pathname} disableButtons={true} />
            <Dropdown
              buttonContent="On this page"
              menuItems={menuItems}
              buttonClassName={{ styleVariant: "default" }}
              menuClassName="lg:hidden pt-[10px]"
            />
            <p className=" mt-[12px] text-[32px] font-[370] tracking-[0.3]">
              {capitalizeFirstLetter(pageName)}
            </p>
            <SubPageBody sections={sections} />
            {showNavCards && (
              <div className="flex inline">
                <NavCard navType="Previous" navLocation={previousStep} />
                {nextStep && <NavCard navType="Next" navLocation={nextStep} />}
              </div>
            )}
          </div>
        </div>
        <GettingStartedPageNav steps={sections} />
      </div>
    </div>
  );
};

export default SubPage;

SubPage.propTypes = {
  apis: PropTypes.array,
  pageName: PropTypes.string.isRequired,
  sections: PropTypes.array.isRequired,
};
