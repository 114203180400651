import React from "react";
import Button from "./Buttons";
import ReactMarkdown from "react-markdown";
import { AuthMarkdownComponents } from "./Markdown";
import PropTypes from "prop-types";

const BasicAuth = ({
  setBasicAuth,
  setUsername,
  setPassword,
  hide,
  username,
  password,
  isBasicAuthed,
  authType,
  details,
}) => {
  const { name, description, type, scheme } = details.filter(
    ({ name }) => authType === name
  )[0];

  return (
    <div className="p-[20px] pt-[20px] dpd-text">
      <div>
        <span className="text-[#6F238A] dark:text-[#b791c4]">{name}</span>
        {` (${type}, ${scheme})`}
        <div className="markdown">
          <ReactMarkdown
            children={description}
            components={AuthMarkdownComponents}
          />
        </div>
      </div>
      {!isBasicAuthed && (
        <React.Fragment>
          <input
            className="min-w-[230px] py-[8px] px-[10px] mt-[10px] mb-[5px] dpd-border-table dark:bg-[#272729]"
            placeholder="username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
          <input
            className="min-w-[230px] py-[8px] px-[10px] my-[5px] dpd-border-table block dark:bg-[#272729]"
            placeholder="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </React.Fragment>
      )}
      <div className="flex justify-center mt-[15px] gap-[10px]">
        {!isBasicAuthed ? (
          <Button
            styleVariant={"greenOutline"}
            onClick={() =>
              username && password
                ? setBasicAuth(btoa(`${username}:${password}`))
                : null
            }
          >
            Authorise
          </Button>
        ) : (
          <Button styleVariant={"grayOutline"} onClick={() => setBasicAuth("")}>
            Log out
          </Button>
        )}
        <Button
          styleVariant={"grayOutline"}
          onClick={() => {
            setUsername("");
            setPassword("");
            hide();
          }}
        >
          Close
        </Button>
      </div>
    </div>
  );
};

export default BasicAuth;

BasicAuth.propTypes = {
  setBasicAuth: PropTypes.func,
  setUsername: PropTypes.func,
  setPassword: PropTypes.func,
  hide: PropTypes.func,
  username: PropTypes.string,
  password: PropTypes.string,
  isBasicAuthed: PropTypes.string,
  authType: PropTypes.string,
  details: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
};
