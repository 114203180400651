import React, { useState } from "react";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import copy from "clipboard-copy";
import CopyIcon from "../Svgs/CopyIcon";
import PropTypes from "prop-types";

const CodeBlock = ({ language, value, inline, withMargin = true }) => {
  const [copied, setCopied] = useState(false);

  const handleCopyClick = () => {
    copy(value);
    setCopied(true);
    setTimeout(() => setCopied(false), 1500);
  };

  return !inline ? (
    <div className="relative grid">
      <SyntaxHighlighter
        language={language}
        PreTag={"pre"}
        useInlineStyles={false}
        className={`p-[16px] bg-[#0000000d] dark:!bg-[#2B2B2B] overflow-x-auto overflow-y-auto max-h-[500px] flex ${
          withMargin ? "mt-[16px]" : ""
        }`}
        codeTagProps={{
          className: "text-[#6F238A] dark:text-[#b791c4] dpd-text-code",
        }}
      >
        {value}
      </SyntaxHighlighter>

      <span
        className="absolute bottom-2 right-2 cursor-pointer"
        onClick={() => handleCopyClick()}
      >
        {!copied ? <CopyIcon /> : <span className="dpd-text-code">copied</span>}
      </span>
    </div>
  ) : (
    <code
      language={language}
      className="px-[4px] text-[#6F238A] dark:text-[#b791c4] bg-[#0000000d] dark:!bg-[#2B2B2B]"
    >
      {value}
    </code>
  );
};
export default CodeBlock;

CodeBlock.propTypes = {
  language: PropTypes.string,
  value: PropTypes.array,
  inline: PropTypes.bool,
  withMargin: PropTypes.bool,
};
