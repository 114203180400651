import React, { useEffect, useState } from "react";
import ReactMarkdown from "react-markdown";
import axios from "axios";

import { joinWithHyphen, objectToCurlCommand } from "../helpers/strings";
import { MarkdownComponents } from "./Markdown";
import Title from "./Title";
import Button from "./Buttons";
import ParametersTable from "./Tables/ParametersTable";
import RequestBody from "./RequestBody";
import ExampleResponseTable from "./Tables/ExampleResponseTable";
import ParamError from "./ParamError";
import RequestTable from "./Tables/RequestTable";
import { buildRequest } from "../helpers/request";
import { useLocation } from "react-router-dom";
import Spinner from "./Spinner";
import PropTypes from "prop-types";

const SwaggerSection = ({
  summary,
  description,
  params,
  requestBodyExample,
  schema,
  responses,
  method,
  authToken,
  toggleModal,
  urlPath,
  basicAuth,
  authMethod,
  authTokenRefresh,
}) => {
  const [allRequiredParamsFilled, setAllRequiredParamsFilled] = useState(false);
  const [tryItOut, setTryItOut] = useState(false);
  const [loading, setLoading] = useState(false);
  const [editBody, setEditBody] = useState("");
  const [editParams, setEditParams] = useState([]);
  const [showParamErros, setShowParamErros] = useState(false);
  const [curlString, setCurlString] = useState("");
  const [fullURLString, setFullURLString] = useState("");
  const [requestResponseMessage, setRequestResponseMessage] = useState("");
  const [requestResponseStatus, setRequestResponseStatus] = useState("");
  const [showSchema, setShowSchema] = useState(false);
  const [showBodyErrors, setShowBodyErrors] = useState(false);
  const { pathname } = useLocation();

  useEffect(() => {
    resetRequest();
    // eslint-disable-next-line
  }, [pathname]);

  useEffect(() => {
    const allFilled = editParams.every(
      (param) => !param.required || param.value !== ""
    );
    setAllRequiredParamsFilled(allFilled);
  }, [editParams]);

  useEffect(() => {
    if (requestBodyExample) {
      setEditBody(JSON.stringify(requestBodyExample, null, 2));
    }
    if (params) {
      const updatedParams = params.map((param) => ({
        ...param,
        value: param.value || "",
      }));
      setEditParams(updatedParams);
    }
  }, [requestBodyExample, params]);

  const resetRequest = () => {
    setShowBodyErrors(false);
    const resetParams = params.map((param) => {
      param.value = "";
      return param;
    });

    setEditParams(resetParams);
    if (requestBodyExample) {
      setEditBody(JSON.stringify(requestBodyExample, null, 2));
    } else {
      setEditBody("");
    }
    setShowParamErros(false);
    setCurlString("");
    setFullURLString("");
    setRequestResponseMessage("");
    setRequestResponseStatus("");
    setShowSchema(false);
    setTryItOut(false);
  };

  const handleOnClick = async () => {
    setShowParamErros(false);
    setShowBodyErrors(false);
    setLoading(true);
    editParams.forEach((param) => {
      if (param.required && param.value === "") {
        return setShowParamErros(true);
      }
    });
    const { curlCommand, url, headers } = objectToCurlCommand({
      urlPath,
      authToken,
      editParams,
      editBody,
      method,
      basicAuth,
      authTokenRefresh,
      authMethod,
    });
    setCurlString(curlCommand);
    setFullURLString(url);
    const requestData = buildRequest(
      method,
      url,
      authToken,
      editBody,
      headers,
      basicAuth,
      authTokenRefresh,
      authMethod
    );

    try {
      const { data, status } = await axios(requestData);
      setRequestResponseMessage(JSON.stringify(data, null, 2));
      setRequestResponseStatus(status);
    } catch (error) {
      if (error.response) {
        const resError = error.response.data
          ? error.response.data.error
          : "Unknown Error";
        const statusError = error.response.status;
        setRequestResponseMessage(JSON.stringify(resError, null, 2));
        setRequestResponseStatus(statusError);
      } else {
        setShowBodyErrors(true);
        setCurlString("");
        console.error(error);
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <React.Fragment>
      {loading && <Spinner />}
      <section id={joinWithHyphen(summary)}>
        <Title method={method} summary={summary} />
        <div className="markdown">
          <ReactMarkdown
            children={description}
            components={MarkdownComponents}
          />
        </div>
        {params && (
          <ParametersTable
            params={editParams}
            setTryItOut={setTryItOut}
            tryItOut={tryItOut}
            authToken={authToken}
            toggleModal={toggleModal}
            setEditParams={setEditParams}
            showParamErros={showParamErros}
            resetRequest={resetRequest}
            basicAuth={basicAuth}
          />
        )}
        {requestBodyExample && (
          <RequestBody
            body={editBody}
            schema={schema}
            tryItOut={tryItOut}
            setEditBody={setEditBody}
            defaultBody={requestBodyExample}
            showSchema={showSchema}
            setShowSchema={setShowSchema}
          />
        )}
        {tryItOut && (
          <React.Fragment>
            <div className="flex justify-between">
              <Button
                className="w-[49%]"
                styleVariant={
                  allRequiredParamsFilled ? "redSolid" : "disabledSolid"
                }
                onClick={handleOnClick}
                disabled={!allRequiredParamsFilled}
              >
                Execute
              </Button>
              <Button
                className="w-[49%]"
                styleVariant={"grayOutline"}
                onClick={resetRequest}
              >
                Clear
              </Button>
            </div>
            {showParamErros && showBodyErrors && (
              <ParamError
                errors={[
                  "Required parameters are not provided",
                  "Invalid JSON body",
                ]}
              />
            )}
            {showParamErros && !showBodyErrors && (
              <ParamError errors={["Required parameters are not provided"]} />
            )}
            {showBodyErrors && !showParamErros && (
              <ParamError errors={["Invalid JSON body"]} />
            )}
            {curlString && (
              <RequestTable
                curlCommand={curlString}
                fullURLString={fullURLString}
                requestResponseMessage={requestResponseMessage}
                requestResponseStatus={requestResponseStatus}
              />
            )}
          </React.Fragment>
        )}
        {responses && !curlString && (
          <ExampleResponseTable responses={responses} />
        )}
      </section>
    </React.Fragment>
  );
};

export default SwaggerSection;

SwaggerSection.propTypes = {
  summary: PropTypes.string,
  description: PropTypes.string,
  params: PropTypes.array,
  requestBodyExample: PropTypes.object,
  schema: PropTypes.object,
  responses: PropTypes.array,
  method: PropTypes.string,
  authToken: PropTypes.string,
  toggleModal: PropTypes.func,
  urlPath: PropTypes.string,
  basicAuth: PropTypes.string,
  authMethod: PropTypes.string,
  authTokenRefresh: PropTypes.string,
};
