import React from "react";
import JsonBlock from "./JsonBlock";
import PropTypes from "prop-types";

const RequestBody = ({
  body,
  tryItOut,
  setEditBody,
  schema,
  showSchema,
  setShowSchema,
}) => {
  return (
    <div className="my-[24px]">
      <h1 className="dpd-heading">Request Body</h1>
      <div className="mt-[16px] flex">
        <button className="dpd-text px-[8px] py-[16px] border-b-[2px] border-[#DC0032] mb-[-2px]">
          JSON
        </button>
      </div>
      {!tryItOut ? (
        !schema ? (
          <JsonBlock value={body} />
        ) : (
          <React.Fragment>
            <div>
              <p className="dpd-text bg-[#0000000d] dark:!bg-[#2B2B2B] pt-[16px] pl-[16px] pb-[10px]">
                <span
                  className={`cursor-pointer ${
                    !showSchema ? "font-bold" : "underline"
                  }`}
                  onClick={() => setShowSchema(false)}
                >
                  Example Value
                </span>{" "}
                |{" "}
                <span
                  className={`cursor-pointer ${
                    showSchema ? "font-bold" : "underline"
                  }`}
                  onClick={() => setShowSchema(true)}
                >
                  Schema
                </span>
              </p>
              <JsonBlock
                value={!showSchema ? body : JSON.stringify(schema, null, 2)}
                fullPadding={false}
              />
            </div>
          </React.Fragment>
        )
      ) : (
        <textarea
          className="p-[16px] !bg-[#0000000d] dark:!bg-[#2B2B2B] w-full min-h-[300px] dpd-text-code"
          value={body}
          onChange={(e) => setEditBody(e.target.value)}
        />
      )}
    </div>
  );
};

export default RequestBody;

RequestBody.propTypes = {
  body: PropTypes.string,
  tryItOut: PropTypes.bool,
  setEditBody: PropTypes.func,
  schema: PropTypes.object,
  showSchema: PropTypes.bool,
  setShowSchema: PropTypes.func,
};
