import BodyParagraph from "../../components/BodyParagraph";
import MessageCard from "../../components/MessageCard";
import AuthenticationFlow from "../../components/Svgs/AuthenticationFlow";

const sections = [
  {
    summary: "Overview",
    body: (
      <>
        <BodyParagraph>
          The first step is to use our authentication API, this allows you to
          control your implementation access to our APIs. You can :
        </BodyParagraph>
        <BodyParagraph>
          <ul className="list-disc list-inside">
            <li>Create new access tokens</li>
            <li>Refresh access tokens</li>
            <li>Remove access tokens</li>
          </ul>
        </BodyParagraph>
        <BodyParagraph>Example Flow below:</BodyParagraph>
        <div className="container mx-auto px-4 sm:px-6 md:px-8">
          <AuthenticationFlow />
        </div>
      </>
    ),
  },
  {
    summary: "Creating an account",
    body: (
      <>
        <BodyParagraph>
          In order to be able to utilise DPD UK Web services as part of your
          Integration there is a requirement to hold a DPD UK or DPD Local UK
          Account along with username and password. These are the details that
          you will have been provided by your account manager to access the DPD
          UK / DPD Local website / MyDPD App. Without an account/login it will
          not be possible to access these services.
        </BodyParagraph>
        <BodyParagraph>
          If you hold an account but believe you have not been issued with login
          details or If you require a dedicated login for your API
          implementation please contact your DPD UK account manager and they
          will be able to assist you.
        </BodyParagraph>
      </>
    ),
  },
  {
    summary: "What Authentication functionality is available?",
    body: (
      <>
        <BodyParagraph>Access Token</BodyParagraph>
        <BodyParagraph>
          You need an access token to be able to use our APIs. The Access Token
          API can be used to create an access token using your API key and
          secret. The access token returned is a JWT Token (JSON Web Token) ,
          Please see “JWT Token Information” for further details on the token
          returned.
        </BodyParagraph>
        <BodyParagraph>
          An access token lasts for 24 hours and must be refreshed before it
          expires, otherwise a new one will have to be requested. Please see
          “JWT Token expiry” for information on how to determine token expiry
          provided within.
        </BodyParagraph>
        <BodyParagraph>
          Authentication is provided within the API’s by way of an “API Key” and
          must be authenticated using Basic authentication, The API key is a
          unique identifier that is allocated and provided to a customer and
          acts as authentication to use the API’s and used to identify the
          customer / user.
        </BodyParagraph>
        <BodyParagraph>
          This API requires the "key" and "secret" to be encoded using base64
          and then passed as an Authorization header in the HTTPS request.
        </BodyParagraph>
        <MessageCard
          messageType={"example"}
          message={
            <div style={{ wordWrap: "break-word" }}>
              Key = key1234567890123456789
              <br />
              Secret = secret9876543210987654321
              <br />
              <br />
              Concatenate the key and secret with a colon in between and encode
              as base64 as a single string:  e.g:
              “key1234567890123456789:secret9876543210987654321” 
            </div>
          }
        />
        <MessageCard
          messageType={"example"}
          message={
            <div style={{ wordWrap: "break-word" }}>
              -Authorization:- Basic
              a2V5MTIzNDU2Nzg5MDEyMzQ1Njc4OTpzZWNyZXQ5ODc2NTQzMjEwOTg3NjU0MzIx 
            </div>
          }
        />
        <BodyParagraph>
          If successful this will return a HTTP 200 Response with accessToken &
          refreshToken. These tokens should be used to manage authentication for
          any following requests
        </BodyParagraph>
      </>
    ),
  },
  {
    summary: "Refresh Access Token",
    body: (
      <>
        <BodyParagraph>
          When you create an access token, a refresh token will also be created.
          Use of this API allows a customer to stay logged in without having to
          provide their key and secret again over a HTTP request. (ie. allows
          the session to be refreshed without having to login again). This API
          should be used by customers when their access token has expired or is
          about to expire . This normally returns a refresh token that is valid
          for 7 days in the future from the point of token creation.  Any new
          token retrieved should be then used overwriting any original token
          obtained; this assists in allowing continuous access for your
          application/s. 
        </BodyParagraph>
      </>
    ),
  },
  {
    summary: "Remove Access Token",
    body: (
      <>
        <BodyParagraph>
          If you need to remove an access token, This API can be used to revoke
          access for an active session/token that has been created via the
          access token API and can be seen as a logout functionality/feature.
          This can also be used if you wish to logout and use another set of
          keys and secrets on the same machine.This assists with preventing
          misuse and keeping tighter control over your applications.
        </BodyParagraph>
      </>
    ),
  },
  {
    summary: "JWT Token Expiry",
    body: (
      <>
        <BodyParagraph>
          Within the token response received via the Access Token API, a field
          exists within the payload body named “expiry”, This is a numeric value
          representing the number of seconds from the date 01/01/1970 at
          00:00:00 UTC time.
        </BodyParagraph>
        <BodyParagraph>
          This numeric value (Epoch Unix Timestamp) when converted into date and
          time gives the information of when the token is due to expire. 
        </BodyParagraph>
      </>
    ),
    messageCards: [
      {
        type: "example",
        message: (
          <>
            1698923072 relates to 02/11/23 11:04:32
            <br />
            This normally returns a value of 24 hours in the future from the
            point of token creation.
          </>
        ),
      },
      {
        type: "note",
        message: "Expiry time could be subject to change.",
      },
    ],
  },
  {
    summary: "JWT Token Information",
    body: (
      <>
        <BodyParagraph>
          JWT token (JSON Web Token) is built of 3 parts
          header.payload.signature
        </BodyParagraph>
        <BodyParagraph>
          <ul>
            <li>
              <span className="font-bold">Header</span> - Holds header
              information about the token such as the algorithm used to sign
              this token and the type for the token.
            </li>
            <li>
              <span className="font-bold">Payload</span> - Holds custom claims,
              this is a JSON object where some custom data related to the token
              is stored.
            </li>
            <li>
              <span className="font-bold">Signature</span> - This is a signature
              which was generated using the header and the payload along with a
              secret key.
            </li>
          </ul>
        </BodyParagraph>
        <BodyParagraph>
          When a JWT token is validated the signature is used to check if the
          header and the payload are valid. If at any point the header or the
          data in the payload changes the signature becomes invalid.
        </BodyParagraph>
        <BodyParagraph>
          Both the access and refresh tokens contain custom data. Below is a
          list of these fields available in the payload body:
        </BodyParagraph>
        <BodyParagraph>
          <ul>
            <li>
              <span className="font-bold">user_id</span>: Unique per
              customer/user. Formatted using businessUnit (1= DPD or 2 = DPD
              Local), customer account number and username in format
              businessUnit_accountNumber_username
            </li>
            <li>
              <span className="font-bold">dpd_account</span>: Customers account
              number
            </li>
            <li>
              <span className="font-bold">dpd_business_id</span>: Customers
              Business Unit (DPD (1) or DPD Local (2))
            </li>
            <li>
              <span className="font-bold">expiry</span>: Token expiry time. This
              is a numeric value representing the number of seconds from the
              date 01/01/1970 at 00:00:00 UTC time.
              <MessageCard
                messageType={"example"}
                message={
                  <div style={{ wordWrap: "break-word", color: "red" }}>
                    1698923072 relates to 02/11/23 11:04:32
                  </div>
                }
              />
            </li>
          </ul>
        </BodyParagraph>
        <BodyParagraph>
          Other data is presented in the JWT token however can be ignored and is
          intended for internal use only 
        </BodyParagraph>
        <BodyParagraph>
          dpd_suspended
          <br />
          dpd_account_override
          <br />
          dpd_expiry
          <br />
          shipping_version
          <br />
          shipping_version
          <br />
          product_access
          <br />
        </BodyParagraph>
      </>
    ),
  },
];

export default sections;
