import ReactMarkdown from "react-markdown";
import { stringify } from "../../helpers/strings";
import JsonBlock from "../JsonBlock";
import { TableMarkdownComponents } from "../Markdown";
import PropTypes from "prop-types";

const ExampleResponseTable = ({ responses }) => {
  const getExampleRes = (exampleResponse) => {
    if (Array.isArray(exampleResponse.error)) {
      return (
        <div className="my-[8px]">
          <JsonBlock value={stringify(exampleResponse.error[0])} />
        </div>
      );
    }

    if (exampleResponse.error) {
      return (
        <div className="my-[8px]">
          <JsonBlock value={stringify(exampleResponse)} />
        </div>
      );
    }

    return (
      <div className="my-[8px]">
        <JsonBlock value={stringify(exampleResponse)} />
      </div>
    );
  };

  const generateRows = (responses) => {
    return responses.map((res, index) => {
      return (
        <tr key={index}>
          <td className="dpd-border-table dpd-text-code py-[8px] text-center px-[16px]">
            {res.responseCode}
          </td>
          <td className="dpd-border-table dpd-text-light py-[8px] px-[16px]">
            <ReactMarkdown
              children={res.description}
              components={TableMarkdownComponents}
            />
            {res.exampleResponse && getExampleRes(res.exampleResponse)}
          </td>
        </tr>
      );
    });
  };

  return (
    <div className="my-[24px]">
      <h1 className="dpd-heading">Responses</h1>
      <table className="mt-[16px] dpd-border-table w-full">
        <thead>
          <tr>
            <th className="dpd-text dpd-border-table w-[10%] text-left py-[8px] px-[16px]">
              Code
            </th>
            <th className="dpd-text dpd-border-table text-left py-[8px] px-[16px]">
              Description
            </th>
          </tr>
        </thead>
        <tbody>{generateRows(responses)}</tbody>
      </table>
    </div>
  );
};

export default ExampleResponseTable;

ExampleResponseTable.propTypes = {
  responses: PropTypes.array,
};
