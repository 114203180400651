import { capitalizeFirstLetter, joinWithHyphen } from "../../helpers/strings";
import PropTypes from "prop-types";
import MessageCard from "../MessageCard";

export const SubPageBody = ({ sections }) => {
  return (
    <div className="mt-[12px]">
      <div className="flex-row">
        {sections.map((section, index) => (
          <section
            className="py-[24px]"
            key={index + 1}
            id={joinWithHyphen(section.summary)}
          >
            <div>
              <h1 className="text-xl font-[370] tracking-heading">
                {capitalizeFirstLetter(section.summary)}
              </h1>
              <div className="dpd-border-bottom 1p pt-[8px] pb-[16px] solid" />
              {section.body}
            </div>
            {section.messageCards &&
              section.messageCards.map((card, index) => (
                <MessageCard
                  key={index}
                  messageType={card.type}
                  message={card.message}
                />
              ))}
          </section>
        ))}
      </div>
    </div>
  );
};

SubPageBody.protoTypes = {
  sections: PropTypes.array,
};
