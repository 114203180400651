import Anchor from "./Anchor";
import CodeBlock from "./CodeBlock";
import Paragraph from "./Paragraph";

export const MarkdownComponents = {
  code({ inline, children, ...props }) {
    return (
      <CodeBlock
        {...props}
        value={children}
        language={"curl"}
        inline={inline}
      />
    );
  },
  p({ children }) {
    return <Paragraph value={children} />;
  },
  ul({ children }) {
    return (
      <ul className="list-disc pl-[24px] mt-[5px] dpd-text">{children}</ul>
    );
  },
};

export const AuthMarkdownComponents = {
  code({ inline, children, ...props }) {
    return (
      <CodeBlock
        {...props}
        value={children}
        language={"curl"}
        inline={inline}
        withMargin={false}
      />
    );
  },
  p({ children }) {
    return <Paragraph value={children} table={true} />;
  },
};

export const TableMarkdownComponents = {
  a({ children, href }) {
    return <Anchor value={children} href={href} />;
  },
  p({ children }) {
    return <Paragraph value={children} table={true} />;
  },

  code({ children }) {
    return <code className="dpd-text">{children}</code>;
  },
  ul({ children }) {
    return <ul className="list-disc pl-[16px]">{children}</ul>;
  },
};
