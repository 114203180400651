import React from "react";
import Nav from "../components/Nav";
import SideNav from "../components/SideNav";
import { useSideNav } from "../context/StateNavContext";
import GettingStartedHero from "../components/GettingStarted/Hero";
import GettingStartedStepCards from "../components/GettingStarted/StepCards";
import { GettingStartedSteps } from "../components/GettingStarted/StepsBody";
import GettingStartedPageNav from "../components/GettingStarted/OnPageNav";
import steps from "../constants/gettingStarted/gettingStartedSteps";
import Dropdown from "../components/Dropdown";
import PropTypes from "prop-types";
import useMenuItems from "../hooks/useMenuItemsSteps";
import useFormattedSteps from "../hooks/useFormattedSteps";
import NavCard from "../components/NavCard";

const GettingStarted = ({ apis }) => {
  const menuItems = useMenuItems(steps);
  const formattedSteps = useFormattedSteps(steps);
  const { sideNavVis, toggleSideNav } = useSideNav();

  const closeNav = () => {
    toggleSideNav();
  };

  return (
    <div>
      <Nav
        pageName={"Getting Started"}
        showHamburgerMenu={true}
        className="absolute z-40"
      />

      <div className="flex  2xl:justify-between">
        <SideNav routes={apis} />
        <div className="w-[100%] max-w-[1000px]">
          {sideNavVis && (
            <div
              onClick={closeNav}
              className="bg-grey-out-of-focus fixed inset-0 z-10 w-screen h-screen"
            />
          )}
          <div className="px-[24px]">
            <Dropdown
              buttonContent="On this page"
              menuItems={menuItems}
              buttonClassName={{ styleVariant: "default" }}
              menuClassName="lg:hidden pt-[10px]"
            />
            <GettingStartedHero />
            <GettingStartedStepCards steps={steps} />
            <GettingStartedSteps steps={steps} />
            <NavCard navType="Next" navLocation={steps[0].title} />
          </div>
        </div>
        <GettingStartedPageNav steps={formattedSteps} />
      </div>
    </div>
  );
};

export default GettingStarted;

GettingStarted.propTypes = {
  apis: PropTypes.array,
};
