import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import SideNavItem from "./SideNavItem";
import { useSideNav } from "../context/StateNavContext";
import PropTypes from "prop-types";
import steps from "../constants/gettingStarted/gettingStartedSteps";

const SideNav = ({ routes, isIntro = false }) => {
  const gettingStartedVersions = steps.map((step) => step.title);
  const { sideNavVis } = useSideNav();
  const [activeItem, setActiveItem] = useState({});
  const { pathname } = useLocation();

  useEffect(() => {
    if (pathname) {
      const page = pathname.split("/")[1];
      const version = pathname.split("/")[2]?.toUpperCase();
      setActiveItem({ page, version });
    }
  }, [pathname]);

  const getActiveVersion = (versions) => {
    const activeVersion = versions.find((version) =>
      pathname.includes(version.toLowerCase())
    );
    return activeVersion || "";
  };

  const isActive = (routeName) => {
    if (routeName.toLowerCase() === "getting%20started") {
      return pathname === "/" || pathname.startsWith("/getting%20started");
    }
    return routeName.toLowerCase() === activeItem.page;
  };
  const generateRoutes = () => {
    return routes.map(({ name, versions }, index) => (
      <SideNavItem
        key={index}
        name={name}
        isActive={isActive(name)}
        versions={versions}
        activeVersion={activeItem.version}
      />
    ));
  };

  return (
    <div
      className={`lg:block lg:sticky absolute z-20 bg-white dark:bg-[#1B1B1D] dark:text-[#ADB5BD]; lg:min-w-[200px] min-w-[65%] dpd-text-light ${
        sideNavVis ? "block" : "hidden"
      } mr-auto dpd-border-right ${
        !isIntro ? "h-[100%] lg:h-auto" : "h-[100vh]"
      }`}
    >
      <div className="block lg:min-w-[200px] min-w-[65%] dpd-text-light">
        <ul>
          <SideNavItem
            name={"Getting Started"}
            isActive={isActive("getting%20started")}
            versions={gettingStartedVersions.map((name) => ({ name }))}
            activeVersion={getActiveVersion(gettingStartedVersions)}
            customPath={true}
          />
          {generateRoutes()}
        </ul>
      </div>
    </div>
  );
};

export default SideNav;

SideNav.propTypes = {
  routes: PropTypes.array,
  isIntro: PropTypes.bool,
};
