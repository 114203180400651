import React from "react";
import Button from "../Buttons";
import { useNavigate } from "react-router-dom";
import { capitalizeFirstLetter, getPageTitle } from "../../helpers/strings";
import HomeIcon from "../Svgs/HomeIcon";
import PropTypes from "prop-types";
import YAML from "json-to-pretty-yaml";
import Dropdown from "../Dropdown";

const Breadcrumb = ({
  paths,
  isAuthed,
  showModal,
  swaggerPath,
  isIntro,
  disableButtons,
}) => {
  const navigate = useNavigate();
  const links = paths.split("/");

  const downloadFile = async (fileTypeToggle) => {
    const swaggerDoc = require(`../../docs/${swaggerPath}`);

    const blob = fileTypeToggle
      ? new Blob([JSON.stringify(swaggerDoc)], {
          type: "application/json",
        })
      : new Blob([YAML.stringify(swaggerDoc)], {
          type: "application/yaml",
        });

    const url = window.URL.createObjectURL(blob);

    const a = document.createElement("a");
    a.href = url;
    a.download = `${getPageTitle(paths)}-${links[links.length - 1]}-schema.${
      fileTypeToggle ? "json" : "yaml"
    }`;
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
  };

  const grenateHref = (index) => {
    if (index < 0 || index >= links.length) {
      return links;
    }
    return links.slice(0, index + 1).join("/");
  };

  const handleDownloadYAML = () => {
    downloadFile(false);
  };

  const handleDownloadJSON = () => {
    downloadFile(true);
  };
  const renderBreadcrumbs = () => {
    return links.map((link, index) => {
      if (index === 0) {
        return (
          <button key={index} onClick={() => navigate("/")}>
            <HomeIcon />
          </button>
        );
      } else {
        return (
          <React.Fragment key={index}>
            <span className="mr-2">
              <svg
                width="3"
                height="6"
                viewBox="0 0 3 6"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M0.57085 0L0 0.705L1.85425 3L0 5.295L0.57085 6L3 3L0.57085 0Z"
                  fill="#808285"
                />
              </svg>
            </span>
            <a
              className="mr-2 dpd-text-small hover:dpd-red pointer"
              href={grenateHref(index)}
              key={link}
            >
              {decodeURIComponent(capitalizeFirstLetter(link))}
            </a>
          </React.Fragment>
        );
      }
    });
  };

  return (
    <div className="flex flex-row my-[16px] items-center">
      {paths && renderBreadcrumbs()}
      {!isIntro && !disableButtons && (
        <span className="ml-auto pb-[8px] dpd-text-button flex">
          <Button
            className={"text-white rounded-md px-[8px] py-[4px] mr-[5px]"}
            styleVariant={!isAuthed ? "redSolid" : "greenSolid"}
            onClick={() => showModal()}
          >
            <span className="flex content-center">
              {!isAuthed && (
                <svg
                  width="21"
                  height="22"
                  viewBox="0 0 21 22"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clipPath="url(#clip0_346_1140)">
                    <path
                      d="M17.3883 11.4053C17.3644 11.1433 17.1732 10.8842 16.9255 10.7887C16.6433 10.6797 16.3587 10.5789 16.0726 10.4846V7.24489C16.0722 4.24908 13.5727 1.8125 10.5002 1.8125C7.42728 1.8125 4.92781 4.24908 4.92781 7.24489V10.4844C4.64172 10.5787 4.35713 10.6795 4.07488 10.7885C3.82677 10.8846 3.63583 11.1433 3.61193 11.4051H3.60938V11.4602V11.5646V18.3197C3.60938 18.6001 3.8097 18.8891 4.07467 18.9917C6.12869 19.7849 8.29067 20.1875 10.5002 20.1875C12.7093 20.1875 14.8713 19.7849 16.9251 18.9917C17.1907 18.8887 17.3906 18.6001 17.3906 18.3197V11.5648V11.4604V11.4053H17.3883ZM11.2593 14.6609V17.1073C11.2593 17.5157 10.9196 17.8466 10.5 17.8466C10.0808 17.8466 9.74116 17.5157 9.74116 17.1073V14.6609C9.29613 14.4041 8.98231 13.9505 8.98231 13.4087C8.98231 12.5912 9.66179 11.9294 10.4998 11.9294C11.3382 11.9294 12.0177 12.5908 12.0177 13.4087C12.0179 13.9505 11.7043 14.4041 11.2593 14.6609ZM14.1562 9.96951C12.9594 9.71911 11.7372 9.59267 10.4998 9.59267C9.26264 9.59267 8.04042 9.71911 6.84359 9.96951V7.24489C6.84359 5.27936 8.48352 3.68007 10.4996 3.68007C12.5161 3.68007 14.1562 5.27936 14.1562 7.24489V9.96951Z"
                      fill="white"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_346_1140">
                      <rect
                        width="21"
                        height="21"
                        fill="white"
                        transform="translate(0 0.5)"
                      />
                    </clipPath>
                  </defs>
                </svg>
              )}
              <p className="self-center pl-[4px]">
                {!isAuthed ? "Authorise" : "Authorised"}
              </p>
            </span>
          </Button>

          <Dropdown
            buttonContent="Download Docs"
            menuItems={[
              { label: "JSON", props: { onClick: handleDownloadJSON } },
              { label: "YAML", props: { onClick: handleDownloadYAML } },
            ]}
            buttonClassName={{ styleVariant: "redButton" }}
          />
        </span>
      )}
    </div>
  );
};

export default Breadcrumb;

Breadcrumb.propTypes = {
  paths: PropTypes.string,
  isAuthed: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  showModal: PropTypes.func,
  swaggerPath: PropTypes.string,
  isIntro: PropTypes.bool,
  disableButtons: PropTypes.bool,
};
