import { SECURITY } from "../constants";
import PropTypes from "prop-types";

export const buildRequest = (
  method,
  url,
  authToken,
  body,
  paramHeaders,
  basicAuth,
  authTokenRefresh,
  authMethod
) => {
  let data;
  try {
    data = body ? JSON.parse(body) : "";
  } catch (error) {
    console.error("Error parsing JSON body:", error);
    return {
      error: "Invalid JSON body",
    };
  }

  const formattedHeaders = paramHeaders
    ? paramHeaders.map(({ name, value }) => ({ [name]: value }))
    : [];

  const authHeader = () => {
    switch (authMethod) {
      case SECURITY.BASIC_AUTH:
        return `Basic ${basicAuth}`;
      case SECURITY.BEARER_TOKEN:
        return `Bearer ${authToken}`;
      case SECURITY.BEARER_REFRESH:
        return `Bearer ${authTokenRefresh}`;
      default:
        return `Bearer ${authToken}`;
    }
  };

  const headers = Object.assign(
    {
      Authorization: authHeader(),
      "Content-Type": "application/json",
    },
    ...formattedHeaders
  );

  return {
    method,
    url,
    headers,
    data,
  };
};

buildRequest.propTypes = {
  method: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  authToken: PropTypes.string.isRequired,
  body: PropTypes.string,
  paramHeaders: PropTypes.array,
  basicAuth: PropTypes.string,
  authTokenRefresh: PropTypes.string,
  authMethod: PropTypes.string.isRequired,
};
