import shippingSections from "./shipping";

const steps = [
  {
    name: "shipping",
    sections: shippingSections,
  },
];

export default steps;
