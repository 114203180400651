import { SECURITY } from "../constants";
import React from "react";

export const capitalizeFirstLetter = (string) =>
  string.charAt(0).toUpperCase() + string.slice(1);

export const stringify = (str) => JSON.stringify(str, null, 2);

export const getPageTitle = (pathname) => {
  const path = pathname.split("/");
  return path[1];
};

export const classNames = (...classes) => {
  return classes.filter(Boolean).join(" ");
};

export const joinWithHyphen = (str) => str.toLowerCase().split(" ").join("-");

export const objectToCurlCommand = (obj) => {
  const queryParams = [];
  let params = "";
  let urlPath = obj.urlPath;
  const headers = [];

  let curlCommand = `curl -X '${obj.method.toUpperCase()}' \\\n`;

  if (urlPath.includes("{")) {
    obj.editParams.forEach((param) => {
      if (param.in === "path" && param.name && param.value) {
        const paramPattern = `{${param.name}}`;
        urlPath = urlPath.replace(paramPattern, param.value);
      }
    });
  }

  if (obj.editParams && Array.isArray(obj.editParams)) {
    obj.editParams.forEach((param) => {
      if (param.in === "header" && param.name && param.value) {
        headers.push({ name: param.name, value: param.value });
        params += ` -H '${param.name}: ${param.value}' \\\n`;
      } else if (param.in === "query" && param.name && param.value) {
        if (param.schema.type === "array") {
          const paramArray = param.value.split(",");
          paramArray.forEach((val) => {
            queryParams.push(`${param.name}=${encodeURIComponent(val)}`);
          });
        } else {
          queryParams.push(`${param.name}=${encodeURIComponent(param.value)}`);
        }
      }
    });
  }

  if (queryParams.length > 0) {
    const queryString = queryParams.join("&");
    urlPath = `${process.env.REACT_APP_SERVICE_URL}${urlPath}?${queryString}`;
  } else {
    urlPath = `${process.env.REACT_APP_SERVICE_URL}${urlPath}`;
  }

  curlCommand += ` '${urlPath}' \\\n`;

  if (!curlCommand.includes("-H 'accept:")) {
    curlCommand += " -H 'accept: */*' \\\n";
  }

  curlCommand += params;

  const getBearerCommand = (token) =>
    obj.editBody
      ? (curlCommand += ` -H 'Authorization: Bearer ${token}' \\\n`)
      : (curlCommand += ` -H 'Authorization: Bearer ${token}'`);

  switch (obj.authMethod) {
    case SECURITY.BASIC_AUTH:
      curlCommand += ` -H 'Authorization: Basic ${obj.basicAuth}'`;
      break;
    case SECURITY.BEARER_TOKEN:
      getBearerCommand(obj.authToken);
      break;
    case SECURITY.BEARER_REFRESH:
      getBearerCommand(obj.authTokenRefresh);
      break;
    default:
      break;
  }

  if (obj.editBody) {
    curlCommand += " -H 'Content-Type: application/json' \\\n";
  }

  if (obj.editBody) {
    curlCommand += ` -d '${obj.editBody}'`;
  }

  return { curlCommand, url: urlPath, headers };
};

export const formatOverviewText = (text) => (
  <>
    {capitalizeFirstLetter(text)}
    <br />
    Overview
  </>
);

export const selectGettingStartedImg = (img, theme) => {
  const imageName = img.toLowerCase();
  return theme === "dark"
    ? `/images/gettingStarted/dark${capitalizeFirstLetter(imageName)}.png`
    : `/images/gettingStarted/${imageName}.png`;
};
