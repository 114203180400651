import React from "react";
import PropTypes from "prop-types";
import { Menu, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import { Fragment } from "react";
import { Link } from "react-scroll";
import { joinWithHyphen } from "../helpers/strings";
import { cva } from "class-variance-authority";

const dropdownButton = cva("dropdownButton", {
  variants: {
    styleVariant: {
      redButton: [
        "inline-flex",
        "text-white",
        "bg-[#E5444B]",
        "hover:bg-red-800",
        "dark:bg-[#760C10]",
        "dark:border-[#FF1512]",
        "dark:hover:bg-[#FF1512]",
        "dark:border",
        "font-medium",
        "rounded",
        "px-[12px]",
        "py-[6px]",
        "transition",
        "duration-300",
        "ease-in-out",
      ],
      default: [
        "inline-flex",
        "w-full",
        "justify-between",
        "gap-x-1.5",
        "rounded-md",
        "bg-white",
        "dark:bg-dark-bg",
        "dark:text-dark-text",
        "px-3",
        "py-2",
        "text-sm",
        "text-gray-900",
        "shadow-sm",
        "ring-1",
        "ring-inset",
        "ring-gray-300",
        "hover:bg-gray-50",
      ],
    },
  },
  compoundVariants: [{ styleVariant: "default" }],
  defaultVariants: {
    styleVariant: "default",
  },
});

const dropdownMenuItem = cva("dropdownMenuItem", {
  variants: {
    styleVariant: {
      post: [
        "p-[5px]",
        "bg-[#E1EFE3]",
        "w-full",
        "text-[#66B553]",
        "dark:bg-[#364F39]",
        "text-left",
      ],
      get: [
        "p-[5px]",
        "bg-[#E5F0FF]",
        "w-full",
        "dark:bg-[#142A47]",
        "text-[#438DED]",
        "text-left",
      ],
      default: [
        "w-full",
        "text-left",
        "px-4",
        "py-2",
        "text-sm",
        "dark:bg-dark-bg",
        "dark:text-dark-text",
        "text-gray-700",
        "hover:bg-gray-100",
        "dark:hover:bg-[#272729]",
        "focus:outline-none",
        "focus:bg-gray-100",
      ],
    },
  },
  compoundVariants: [{ styleVariant: "default" }],
  defaultVariants: {
    styleVariant: "default",
  },
});

const Dropdown = ({
  buttonContent,
  menuItems,
  buttonClassName,
  menuClassName,
}) => {
  return (
    <div className="flex">
      <Menu
        as="div"
        className={`w-full relative inline-block text-left ${menuClassName}`}
      >
        <div>
          <Menu.Button
            className={dropdownButton({
              styleVariant: buttonClassName.styleVariant,
            })}
          >
            {buttonContent}
            <ChevronDownIcon
              className="-mr-1 h-5 w-5 self-center transform transition-transform duration-300"
              aria-hidden="true"
            />
          </Menu.Button>
        </div>

        <Transition
          as={Fragment}
          enter="transition ease-out duration-200"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-150"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="overflow-hidden absolute z-10 mt-2 w-full origin-top-center rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
            <div className="">
              {menuItems.map((menuItem, index) => (
                <Link
                  key={index}
                  to={joinWithHyphen(menuItem.label)}
                  smooth
                  spy
                >
                  <Menu.Item
                    as="button"
                    key={index}
                    className={dropdownMenuItem({
                      styleVariant: menuItem.styleVariant,
                    })}
                    {...menuItem.props}
                  >
                    {menuItem.label}
                  </Menu.Item>
                </Link>
              ))}
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
    </div>
  );
};

Dropdown.propTypes = {
  buttonContent: PropTypes.node.isRequired,
  menuItems: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      props: PropTypes.object,
      styleVariant: PropTypes.string,
    })
  ).isRequired,
  buttonClassName: PropTypes.shape({
    styleVariant: PropTypes.string,
  }),
  menuClassName: PropTypes.string,
};

export default Dropdown;
