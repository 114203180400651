export const filterDeprecatedPathsAndVersions = (apis) => {
  const resultArray = [];

  for (const item of apis) {
    const nonDeprecatedVersions = item.versions
      .filter((version) => !version.deprecated)
      .map((version) => version);

    if (nonDeprecatedVersions.length > 0) {
      resultArray.push({
        path: item.path,
        name: item.name,
        versions: nonDeprecatedVersions,
      });
    }
  }

  return resultArray;
};

export const getHighestVersion = (array) => {
  let highestVersion = "";
  let highestItem = null;

  for (const item of array) {
    if (!highestItem || item.name > highestVersion) {
      highestVersion = item.name;
      highestItem = item;
    }
  }
  return highestItem;
};
