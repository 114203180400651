import { METHODS } from "../constants";
import PropTypes from "prop-types";

const Title = ({ method, summary }) => {
  const methodBadge = () => {
    switch (method) {
      case METHODS.GET:
        return "bg-[#e5f0ff] text-[#438DED] dark:bg-[#0F2C4B] dark:text-[#E5F0FF]";
      case METHODS.POST:
        return "bg-[#E1EFE3] text-[#66b553] dark:bg-[#509E2F] dark:text-[#E1EFE3]";
      default:
        return "";
    }
  };

  return (
    <div className="flex items-center dpd-border-bottom">
      <div
        className={`mr-[8px] px-[6px] rounded-[14px] dpd-nav-text ${methodBadge()} capitalize`}
      >
        {method}
      </div>
      <h1 className="dpd-heading">{summary}</h1>
    </div>
  );
};

export default Title;

Title.propTypes = {
  method: PropTypes.string,
  summary: PropTypes.string,
};
