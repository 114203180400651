import BodyParagraph from "../../components/BodyParagraph";
import MessageCard from "../../components/MessageCard";

const sections = [
  {
    summary: "Overview",
    body: (
      <>
        <BodyParagraph>
          In order to create a shipment several helper methods have been created
          which aid in creation of a shipment
        </BodyParagraph>
      </>
    ),
  },
  {
    summary: "Testing Information for Shipping",
    messageCards: [
      {
        type: "warning",
        message:
          "Please be aware not to use sensitive customer details whilst within the testing process, this also applies to the Email / SMS notification details. We highly recommend leaving blank or populating with your own details as notifications for both SMS / Email may be triggered and will be sent out to specified numbers / addresses. ",
      },
      {
        type: "note",
        message:
          "When testing your API implementation, Billing of parcels will not take place unless the parcel / label is seen through the DPD Group network.",
      },
    ],
  },
  {
    summary: "Consolidation Rules",
    body: (
      <>
        <BodyParagraph>
          Consolidation of Shipments is handled internally by DPD Group UK
          Shipping systems, Please see default criteria that used is below :
        </BodyParagraph>
        <BodyParagraph>
          Shipment Date
          <br />
          Account Number
          <br />
          Network Service
          <br />
          Receivers Name
          <br />
          All Lines of Address
          <br />
          Postcode
          <br />
        </BodyParagraph>
        <MessageCard
          messageType="note"
          message="All characters including punctuation marks and spaces are recognised. Thus if two parcels had identical values for every field except the Receivers Name which for one parcel contains additional spaces, this would result in our system treating these as two separate consignments."
        />
        <BodyParagraph>
          Once sign off is obtained It is recommended that the first live runs
          be of small volumes, and monitored by both DPD and the client.
        </BodyParagraph>
      </>
    ),
  },
  {
    summary: "Barcode Metrics",
    body: (
      <>
        <BodyParagraph>
          There are important dimensional properties that must be fulfilled for
          labels to pass verification. It is highly recommended that this is
          validated prior to sending physical label examples for sign off.
        </BodyParagraph>
        <MessageCard
          messageType="note"
          message="Use only white label stock (Direct thermal printing preferred)."
        />
        <BodyParagraph>
          <span className="font-bold">Laser/Thermal Printer</span>
          <br />
          Overall Bar Code Size
          <br />
          Barcode dimensions:
          <br />
          Minimum: 91.5mm x 25mm height
        </BodyParagraph>
        <BodyParagraph>
          <span className="font-bold">Bar-Code Quiet Zones</span>
          <br />
          You must have a minimum of 5 mm on either side of the bar code
          extending at 45 degrees from the corners. Additionally, you must have
          a minimum of 1 mm on top and bottom of the entire barcode.
        </BodyParagraph>
      </>
    ),
  },
  {
    summary: "Get Label by Shipment ID Guidance",
    body: (
      <>
        <MessageCard
          messageType="warning"
          message="Printing HTML output provided via API to a Thermal printer may cause label output to be scaled under minimum requirements and fail verification once label is received by ourselves and analysed, therefore is not recommended. "
        />
        <BodyParagraph>
          The HTML output includes padding around the label to ensure when
          printed to A4 stationery this is correct, this makes HTML output too
          large and unsuitable to be printed via thermal printer/thermal
          stationery.
        </BodyParagraph>
      </>
    ),
  },
];

export default sections;
