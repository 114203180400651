import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Nav from "../components/Nav";
import Breadcrumb from "../components/Breadcrumb/Breadcrumb";
import useSwaggerPath from "../hooks/useSwaggerPath";
import {
  swaggerAvailableAuth,
  swaggerTransformer,
} from "../helpers/swagger.transformer";
import SideNav from "../components/SideNav";
import { getPageTitle } from "../helpers/strings";
import OnPageNav from "../components/OnPageNav";
import useModal from "../hooks/useModal";
import AuthModal from "../components/AuthModal";
import SwaggerSection from "../components/SwaggerSection";
import { useSideNav } from "../context/StateNavContext";
import { PAGES } from "../constants";
import PropTypes from "prop-types";
import useMenuItems from "../hooks/useMenuItemDocs";
import Dropdown from "../components/Dropdown";

const Docs = ({ apis }) => {
  const { sideNavVis, toggleSideNav } = useSideNav();
  const swaggerPath = useSwaggerPath(apis);
  const [swaggerData, setSwaggerData] = useState("");
  const [availableAuth, setAvailableAuth] = useState("");
  const [authToken, setAuthToken] = useState("");
  const [basicAuth, setBasicAuth] = useState("");
  const [authTokenRefresh, setAuthTokenRefresh] = useState("");
  const menuItems = useMenuItems(swaggerData);
  const { isShowing, toggle } = useModal();
  const { pathname } = useLocation();

  useEffect(() => {
    if (swaggerPath) {
      const swaggerDoc = require(`../docs/${swaggerPath}`);
      setAvailableAuth(swaggerAvailableAuth(swaggerDoc));
      setSwaggerData(swaggerTransformer(swaggerDoc));
    }
  }, [swaggerPath]);

  const closeNav = () => {
    toggleSideNav();
  };

  const renderSwaggerDocs = () => {
    return swaggerData.map(
      (
        {
          summary,
          description,
          params,
          requestBodyExample,
          schema,
          responses,
          method,
          urlPath,
          authMethod,
        },
        index
      ) => (
        <SwaggerSection
          summary={summary}
          description={description}
          params={params}
          requestBodyExample={requestBodyExample}
          schema={schema}
          responses={responses}
          method={method}
          authToken={authToken}
          authTokenRefresh={authTokenRefresh}
          basicAuth={basicAuth}
          toggleModal={toggle}
          urlPath={urlPath}
          authMethod={authMethod}
          key={index}
        />
      )
    );
  };

  const checkAuthed = () => {
    const pageTitle = getPageTitle(pathname);
    switch (pageTitle) {
      case PAGES.AUTH:
        return basicAuth || authTokenRefresh;
      case PAGES.SHIPPING:
        return authToken;
      case PAGES.RETURNS:
        return authToken;
      case PAGES.PICKUP:
        return authToken;
      case PAGES.TRACKING:
        return authToken;
      default:
        return false;
    }
  };

  return (
    <div>
      <AuthModal
        isShowing={isShowing}
        hide={toggle}
        setAuthToken={setAuthToken}
        setBasicAuth={setBasicAuth}
        setAuthTokenRefresh={setAuthTokenRefresh}
        isAuthed={authToken}
        isBasicAuthed={basicAuth}
        isRefreshAuthed={authTokenRefresh}
        page={getPageTitle(pathname)}
        availableAuth={availableAuth}
      />
      <Nav
        pageName={getPageTitle(pathname)}
        showHamburgerMenu={true}
        className="absolute z-40"
      />

      <div className="flex justify-between relative 2xl:justify-center">
        <SideNav routes={apis} />
        <div className="w-[100%] max-w-[1000px]">
          {sideNavVis && (
            <div
              onClick={closeNav}
              className="bg-grey-out-of-focus fixed inset-0 z-10 w-screen h-screen"
            />
          )}
          <div className="px-[24px]">
            <Breadcrumb
              paths={pathname}
              showModal={toggle}
              isAuthed={checkAuthed()}
              swaggerPath={swaggerPath}
            />
            {swaggerData && (
              <Dropdown
                buttonContent="On this page"
                menuItems={menuItems}
                buttonClassName={{ styleVariant: "default" }}
                menuClassName="lg:hidden pb-[10px]"
              />
            )}
            {swaggerData && renderSwaggerDocs()}
          </div>
        </div>
        {swaggerData && <OnPageNav methods={swaggerData} />}
      </div>
    </div>
  );
};

export default Docs;

Docs.propTypes = {
  apis: PropTypes.array,
};
