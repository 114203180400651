import { capitalizeFirstLetter, joinWithHyphen } from "../../helpers/strings";
import useFormattedSteps from "../../hooks/useFormattedSteps";
import PropTypes from "prop-types";

export const GettingStartedSteps = ({ steps }) => {
  const formattedSteps = useFormattedSteps(steps);

  return (
    <div className="mt-[24px]">
      <div className="flex-row">
        {steps.map((step, index) => (
          <section
            className="py-[24px]"
            key={index + 1}
            id={joinWithHyphen(formattedSteps[index + 1].summary)}
          >
            <div>
              <h1 className="text-xl font-[370] tracking-heading">{`Step ${
                index + 1
              }: ${capitalizeFirstLetter(step.title)}`}</h1>
              <div className="border-b pt-2 pb-4" />
              <p className="font-light py-8">{step.body}</p>
            </div>
          </section>
        ))}
      </div>
    </div>
  );
};

GettingStartedSteps.protoTypes = {
  steps: PropTypes.array,
};
