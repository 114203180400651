import React, { useState } from "react";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import copy from "clipboard-copy";
import CopyIcon from "./Svgs/CopyIcon";
import PropTypes from "prop-types";
import { ThemeContext } from "../context/ThemeContext";

const JsonBlock = ({ value, fullPadding = true }) => {
  const [copied, setCopied] = useState(false);

  const handleCopyClick = () => {
    copy(value);
    setCopied(true);
    setTimeout(() => setCopied(false), 1500);
  };
  const { theme } = React.useContext(ThemeContext);

  const customTheme = {
    property: {
      color: theme === "dark" ? "#ADB5BD" : "#414042",
    },
    string: {
      color: "#509E2F",
    },
    number: {
      color: "#E5444B",
    },
  };

  return (
    <div className="relative grid">
      <SyntaxHighlighter
        language="json"
        className={`test dark:!bg-[#2B2B2B] !bg-[#0000000d]
        } overflow-x-auto overflow-y-auto max-h-[500px] flex ${
          fullPadding ? "p-[16px]" : "p-[16px] pt-[0px]"
        }`}
        codeTagProps={{ className: "dpd-text-code" }}
        style={customTheme}
      >
        {value}
      </SyntaxHighlighter>

      <span
        className="absolute bottom-2 right-2 cursor-pointer"
        onClick={() => handleCopyClick()}
      >
        {!copied ? <CopyIcon /> : <span className="dpd-text-code">copied</span>}
      </span>
    </div>
  );
};
export default JsonBlock;

JsonBlock.propTypes = {
  value: PropTypes.string,
  fullPadding: PropTypes.bool,
};
