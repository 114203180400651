import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { INTRODUCTION } from "../constants";
import PropTypes from "prop-types";

const SideNavItem = ({
  name,
  isActive,
  versions,
  activeVersion,
  customPath,
}) => {
  const [collapseNav, setCollapsNav] = useState(true);
  const { pathname } = useLocation();
  const introductionIsActive =
    pathname?.includes(INTRODUCTION) && pathname?.includes(name?.toLowerCase());

  useEffect(() => {
    pathname === `/${name?.toLowerCase()}/${activeVersion?.toLowerCase()}`
      ? setCollapsNav(false)
      : setCollapsNav(true);
  }, [pathname, name, activeVersion]);

  return (
    <li
      className={`dpd-border-top ${
        isActive
          ? "bg-[#0000000d] dark:bg-[#272729] border-r-[3px] border-[#DC0032]"
          : ""
      }`}
    >
      <div
        className={"flex py-[20px] pl-[20px] items-center cursor-pointer"}
        onClick={() => setCollapsNav(!collapseNav)}
      >
        {customPath ? (
          <Link
            to={`/${name.toLowerCase()}`}
            className={isActive ? "text-[#DC0032]" : ""}
          >
            {name}
          </Link>
        ) : (
          <span className={isActive ? "text-[#DC0032]" : ""}>{name}</span>
        )}
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          className={`${!collapseNav ? "rotate-90" : ""} ml-auto mr-[8px]`}
          fill="none"
          alt="Arrow"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g id="MdChevronRight" clipPath="url(#clip0_488_1770)">
            <path
              id="Vector"
              d="M9.99984 6L8.58984 7.41L13.1698 12L8.58984 16.59L9.99984 18L15.9998 12L9.99984 6Z"
              fill="#808285"
            />
          </g>
          <defs>
            <clipPath id="clip0_488_1770">
              <rect width="24" height="24" fill="white" />
            </clipPath>
          </defs>
        </svg>
      </div>
      {!collapseNav && (
        <div className={`pl-[28px] mt-[-15px] pb-[10px]`}>
          {!customPath && (
            <Link
              className={`block mb-[8px] ${
                introductionIsActive ? "text-[#DC0032]" : ""
              }`}
              to={`/${name.toLowerCase()}/introduction`}
            >
              Introduction
            </Link>
          )}
          {versions.map((version, index) => (
            <Link
              key={index}
              className={`block mb-[8px] ${
                activeVersion === version.name && isActive
                  ? "text-[#DC0032]"
                  : ""
              }`}
              to={`/${name.toLowerCase()}/${version.name.toLowerCase()}`}
            >
              {!customPath ? `Specification ${version.name}` : version.name}
            </Link>
          ))}
        </div>
      )}
    </li>
  );
};

export default SideNavItem;

SideNavItem.propTypes = {
  name: PropTypes.string,
  isActive: PropTypes.bool,
  versions: PropTypes.array,
  activeVersion: PropTypes.string,
};
