import PropTypes from "prop-types";

const ParamError = ({ errors }) => {
  return (
    <div className="w-full dpd-button-border bg-[#feebeb] dpd-text rounded-md mt-[20px] py-[15px] px-[10px]">
      <p>Please correct the following validation errors and try again</p>
      <ul className="list-disc list-inside	pl-[10px] mt-[8px]">
        {errors.map((error) => (
          <li>{error}</li>
        ))}
      </ul>
    </div>
  );
};

export default ParamError;

ParamError.propTypes = {
  errors: PropTypes.array,
};
