import ReactMarkdown from "react-markdown";
import Button from "./Buttons";
import { AuthMarkdownComponents } from "./Markdown";
import PropTypes from "prop-types";

const bearerTokenRefresh = ({
  setAuthTokenRefresh,
  setInput,
  hide,
  input,
  isRefreshAuthed,
  authType,
  details,
}) => {
  const { name, description, type, scheme } = details.filter(
    ({ name }) => authType === name
  )[0];

  return (
    <div className="p-[20px] pt-[20px] dpd-text">
      <div>
        <span className="text-[#6F238A] dark:text-[#b791c4]">{name}</span>
        {` (${type}, ${scheme})`}
        <div className="markdown">
          <ReactMarkdown
            children={description}
            components={AuthMarkdownComponents}
          />
        </div>
      </div>
      {!isRefreshAuthed && (
        <input
          className="min-w-[230px] py-[8px] px-[10px] my-[10px] dpd-border-table dark:bg-[#272729]"
          placeholder="Bearer refresh token"
          value={input}
          onChange={(e) => setInput(e.target.value)}
        />
      )}
      <div className="flex justify-center mt-[15px] gap-[10px]">
        {!isRefreshAuthed ? (
          <Button
            styleVariant={"greenOutline"}
            onClick={() => setAuthTokenRefresh(input)}
          >
            Authorise
          </Button>
        ) : (
          <Button
            styleVariant={"grayOutline"}
            onClick={() => setAuthTokenRefresh("")}
          >
            Log out
          </Button>
        )}
        <Button
          styleVariant={"grayOutline"}
          onClick={() => {
            setInput("");
            hide();
          }}
        >
          Close
        </Button>
      </div>
    </div>
  );
};

export default bearerTokenRefresh;

bearerTokenRefresh.propTypes = {
  setAuthTokenRefresh: PropTypes.func,
  setInput: PropTypes.func,
  hide: PropTypes.func,
  input: PropTypes.string,
  isRefreshAuthed: PropTypes.string,
  authType: PropTypes.string,
  details: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
};
