import React from "react";
import ReactDOM from "react-dom/client";
import PageRouter from "./router";
import "./sentry";
import "./styles/index.scss";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <PageRouter />
  </React.StrictMode>
);
