import { useMemo } from "react";

export default function useMenuItems(methods) {
  return useMemo(() => {
    const result = [];
    const postMethods = [];
    const getMethods = [];

    if (!Array.isArray(methods)) {
      return [];
    }

    methods.forEach((method) => {
      if (method.method === "get") {
        getMethods.push({
          label: method.summary,
          props: {},
        });
      } else if (method.method === "post") {
        postMethods.push({
          label: method.summary,
          props: {},
        });
      }
    });

    if (postMethods.length > 0) {
      result.push({
        label: "POST",
        props: {},
        styleVariant: "post",
      });
      postMethods.forEach((method) => {
        result.push({
          label: method.label,
          props: {},
        });
      });
    }
    if (getMethods.length > 0) {
      result.push({
        label: "GET",
        props: {},
        styleVariant: "get",
      });
      getMethods.forEach((method) => {
        result.push({
          label: method.label,
          props: {},
        });
      });
    }
    return result;
  }, [methods]);
}
