import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";

const useSwaggerPath = (apis) => {
  const [swaggerPath, setSwaggerPath] = useState("");
  const { pathname } = useLocation();
  const version = pathname.split("/").pop().toUpperCase();
  const routeName = pathname.substring(1).split("/")[0];

  useEffect(() => {
    apis.forEach((api) => {
      if (api.name.toLowerCase() === routeName.toLowerCase()) {
        api.versions.forEach((apiVersion) => {
          if (apiVersion.name === version) {
            setSwaggerPath(`${api.path}/${apiVersion.path}/schema.json`);
          }
        });
      }
    });
  }, [apis, routeName, version]);

  return swaggerPath;
};

export default useSwaggerPath;
