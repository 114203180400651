import React from "react";
import { ThemeContext } from "../../context/ThemeContext";

const DPDLogo = () => {
  const { theme } = React.useContext(ThemeContext);

  return (
    <div className="mr-3">
      {theme === "dark" ? (
        <svg
          width="77"
          height="32"
          viewBox="0 0 77 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g
            id="Logos/Brand/DPD/WhiteRed/logoDPDWhiteRed"
            clipPath="url(#clip0_626_10506)"
          >
            <mask
              id="mask0_626_11130"
              style={{ maskType: "alpha" }}
              maskUnits="userSpaceOnUse"
              x="0"
              y="0"
              width="24"
              height="24"
            >
              <rect
                id="boundingBox"
                width="76.5714"
                height="32"
                fill="#C4C4C4"
              />
            </mask>
            <g mask="url(#mask0_626_10506)">
              <path
                id="Vector"
                d="M19.3269 14.2818C19.2547 14.3181 19.1746 14.3364 19.0936 14.3349C19.0126 14.3333 18.9333 14.3121 18.8626 14.2731L18.1097 13.8313C18.0434 13.7883 17.9886 13.7301 17.95 13.6616L17.9423 13.6492C17.8966 13.579 17.8697 13.4985 17.8642 13.4152L17.8451 12.5497C17.8461 12.4699 17.867 12.3916 17.9061 12.3217C17.9451 12.2519 18.001 12.1926 18.0689 12.1493L27.1171 6.94547L14.4193 0.113028C14.2603 0.0379785 14.0864 -0.000976563 13.9102 -0.000976562C13.734 -0.000976563 13.5601 0.0379785 13.4011 0.113028L0.703315 6.94547L14.9282 15.1198C14.9967 15.1612 15.0538 15.2188 15.0943 15.2874C15.1347 15.3559 15.1573 15.4333 15.16 15.5126V27.4375C15.1556 27.5174 15.1313 27.595 15.0892 27.6633C15.0471 27.7317 14.9885 27.7887 14.9187 27.8293L14.1532 28.2488C14.0822 28.2837 14.0038 28.3013 13.9245 28.3H13.91C13.8255 28.3037 13.7414 28.286 13.6657 28.2488L12.8975 27.8293C12.8285 27.7882 12.7707 27.731 12.7294 27.6627C12.688 27.5943 12.6643 27.517 12.6604 27.4375V16.8071C12.6553 16.7654 12.6412 16.7252 12.6192 16.6892C12.5972 16.6532 12.5677 16.6222 12.5327 16.5983L0 9.39705V23.5026C0 23.8183 0.22529 24.2074 0.50166 24.3681L13.4087 31.8812C13.5641 31.9593 13.7359 32 13.9102 32C14.0845 32 14.2563 31.9593 14.4117 31.8812L27.3206 24.3669C27.5959 24.2059 27.8204 23.8183 27.8204 23.5015V9.39705L19.3269 14.2818Z"
                fill="#DC0032"
              />
              <path
                id="Vector_2"
                d="M47.6154 23.7066C46.2175 24.072 44.3969 24.2526 42.8123 24.2526C38.7487 24.2526 36.0578 22.1179 36.0578 18.2108C36.0578 14.5129 38.5642 12.1177 42.2332 12.1177C43.0509 12.1177 43.9208 12.2205 44.4491 12.4816V7.11523H47.6131L47.6154 23.7066ZM44.4514 15.2421C43.9501 15.0077 43.2907 14.8775 42.4978 14.8775C40.5731 14.8775 39.2797 16.0501 39.2797 18.1073C39.2797 20.3214 40.6779 21.5719 42.9209 21.5719C43.3166 21.5719 43.9231 21.5451 44.4514 21.4417V15.2421ZM73.5961 23.7066C72.1967 24.072 70.3765 24.2526 68.793 24.2526C64.7302 24.2526 62.0377 22.1179 62.0377 18.2108C62.0377 14.5129 64.5453 12.1177 68.2132 12.1177C69.0309 12.1177 69.9015 12.2205 70.4295 12.4816V7.11523H73.5957L73.5961 23.7066ZM70.4299 15.2421C69.9282 15.0077 69.2683 14.8775 68.477 14.8775C66.5511 14.8775 65.2585 16.0501 65.2585 18.1073C65.2585 20.3214 66.6564 21.5719 68.8993 21.5719C69.2946 21.5719 69.9019 21.5451 70.4299 21.4417V15.2421ZM52.5946 15.2166C53.1218 15.0077 53.8595 14.9302 54.493 14.9302C56.4459 14.9302 57.7915 16.0501 57.7915 18.0535C57.7915 20.4181 56.301 21.4879 54.3081 21.5154V24.2496C54.3604 24.2496 54.4133 24.2496 54.4671 24.2496C58.5562 24.2496 61.0108 21.9846 61.0108 17.9481C61.0108 14.2754 58.3988 12.1155 54.5464 12.1155C52.5946 12.1155 50.6677 12.5584 49.4002 13.0784V28.6835H52.5935L52.5946 15.2166Z"
                fill="white"
              />
            </g>
          </g>
          <defs>
            <clipPath id="clip0_626_10506">
              <rect width="76.5714" height="32" fill="white" />
            </clipPath>
          </defs>
        </svg>
      ) : (
        <svg
          width="73"
          height="32"
          viewBox="0 0 73 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g id="logoPrimaryDpdRedGrad" clipPath="url(#clip0_662_1527)">
            <mask
              id="mask0_626_11130"
              style={{ maskType: "alpha" }}
              maskUnits="userSpaceOnUse"
              x="0"
              y="0"
              width="24"
              height="24"
            >
              <rect id="boundingBox" width="73" height="32" fill="#C4C4C4" />
            </mask>
            <g mask="url(#mask0_662_1527)">
              <path
                id="Vector"
                d="M18.4254 14.2818C18.3566 14.3181 18.2803 14.3364 18.2031 14.3349C18.1258 14.3333 18.0502 14.3121 17.9828 14.2731L17.265 13.8313C17.2018 13.7883 17.1495 13.7301 17.1128 13.6616L17.1055 13.6492C17.0619 13.579 17.0362 13.4985 17.031 13.4152L17.0128 12.5497C17.0137 12.4699 17.0337 12.3916 17.0709 12.3217C17.1081 12.2519 17.1614 12.1926 17.2261 12.1493L25.8523 6.94547L13.7467 0.113028C13.5952 0.0379785 13.4294 -0.000976563 13.2614 -0.000976562C13.0934 -0.000976563 12.9276 0.0379785 12.7761 0.113028L0.670511 6.94547L14.2319 15.1198C14.2973 15.1612 14.3517 15.2188 14.3902 15.2874C14.4288 15.3559 14.4503 15.4333 14.4529 15.5126V27.4375C14.4487 27.5174 14.4256 27.595 14.3854 27.6633C14.3453 27.7317 14.2894 27.7887 14.2228 27.8293L13.4931 28.2488C13.4254 28.2837 13.3506 28.3013 13.275 28.3H13.2612C13.1806 28.3037 13.1005 28.286 13.0283 28.2488L12.296 27.8293C12.2301 27.7882 12.1751 27.731 12.1356 27.6627C12.0962 27.5943 12.0736 27.517 12.0699 27.4375V16.8071C12.065 16.7654 12.0516 16.7252 12.0306 16.6892C12.0096 16.6532 11.9815 16.6222 11.9482 16.5983L0 9.39705V23.5026C0 23.8183 0.214782 24.2074 0.478262 24.3681L12.7833 31.8812C12.9314 31.9593 13.0952 32 13.2614 32C13.4276 32 13.5914 31.9593 13.7395 31.8812L26.0464 24.3669C26.3087 24.2059 26.5228 23.8183 26.5228 23.5014V9.39705L18.4254 14.2818Z"
                fill="url(#paint0_linear_662_1527)"
              />
              <path
                id="Vector_2"
                d="M45.3945 23.7066C44.0618 24.072 42.3261 24.2526 40.8154 24.2526C36.9413 24.2526 34.3759 22.1179 34.3759 18.2108C34.3759 14.5129 36.7654 12.1177 40.2634 12.1177C41.0429 12.1177 41.8722 12.2205 42.3759 12.4816V7.11523H45.3923L45.3945 23.7066ZM42.3781 15.2421C41.9002 15.0077 41.2715 14.8775 40.5156 14.8775C38.6807 14.8775 37.4476 16.0501 37.4476 18.1073C37.4476 20.3214 38.7806 21.5719 40.919 21.5719C41.2962 21.5719 41.8744 21.5451 42.3781 21.4417V15.2421ZM70.1634 23.7066C68.8293 24.072 67.094 24.2526 65.5843 24.2526C61.711 24.2526 59.1442 22.1179 59.1442 18.2108C59.1442 14.5129 61.5347 12.1177 65.0316 12.1177C65.8111 12.1177 66.6412 12.2205 67.1445 12.4816V7.11523H70.1631L70.1634 23.7066ZM67.1449 15.2421C66.6666 15.0077 66.0375 14.8775 65.2831 14.8775C63.4471 14.8775 62.2147 16.0501 62.2147 18.1073C62.2147 20.3214 63.5474 21.5719 65.6857 21.5719C66.0626 21.5719 66.6415 21.5451 67.1449 21.4417V15.2421ZM50.1415 15.2166C50.6441 15.0077 51.3473 14.9302 51.9513 14.9302C53.8131 14.9302 55.096 16.0501 55.096 18.0535C55.096 20.4181 53.675 21.4879 51.7751 21.5154V24.2496C51.8249 24.2496 51.8754 24.2496 51.9266 24.2496C55.825 24.2496 58.1651 21.9847 58.1651 17.9481C58.1651 14.2754 55.6749 12.1155 52.0022 12.1155C50.1415 12.1155 48.3044 12.5584 47.096 13.0784V28.6835H50.1404L50.1415 15.2166Z"
                fill="#414042"
              />
            </g>
          </g>
          <defs>
            <linearGradient
              id="paint0_linear_662_1527"
              x1="13.3662"
              y1="10.4002"
              x2="1.05695"
              y2="23.6839"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#DC0032" />
              <stop offset="0.2" stopColor="#D0012D" />
              <stop offset="0.4" stopColor="#C30229" />
              <stop offset="0.6" stopColor="#B70324" />
              <stop offset="0.8" stopColor="#AB041F" />
              <stop offset="1" stopColor="#9F041B" />
            </linearGradient>
            <clipPath id="clip0_662_1527">
              <rect width="73" height="32" fill="white" />
            </clipPath>
          </defs>
        </svg>
      )}
    </div>
  );
};

export default DPDLogo;
