import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import Docs from "./pages/Docs";
import GettingStarted from "./pages/GettingStarted";
import PageNotFound from "./pages/404";
import { SideNavProvider } from "./context/StateNavContext";
import { ThemeProvider } from "./context/ThemeContext";
import gettingStartedSteps from "./constants/gettingStarted/gettingStartedSteps";
import docsSteps from "./constants/docs";
import SubPage from "./pages/SubPage";
import {
  filterDeprecatedPathsAndVersions,
  getHighestVersion,
} from "./helpers/arrays";

export default function PageRouter() {
  const { apis } = require("./docs/index.json");
  const availableDocRoutes = filterDeprecatedPathsAndVersions(apis);
  const getSections = (name) => {
    const step = docsSteps.find((step) => step.name === name);
    return step ? step.sections : [];
  };

  return (
    <Router>
      <ThemeProvider>
        <SideNavProvider>
          <div>
            <Routes>
              <Route
                path="/"
                element={<GettingStarted apis={availableDocRoutes} />}
              />
              <Route path="/getting started" element={<Navigate to="/" />} />
              {gettingStartedSteps.map((step, index) => (
                <Route
                  key={index}
                  path={`/getting started/${step.title.toLowerCase()}`}
                  element={
                    <SubPage
                      apis={availableDocRoutes}
                      pageName={step.title}
                      sections={step.sections}
                    />
                  }
                />
              ))}
              <Route
                path="/getting started/select api"
                element={<Navigate to="/getting started/select api/sandbox" />}
              />
              <Route
                path="*"
                element={<PageNotFound apis={availableDocRoutes} />}
              />

              {availableDocRoutes.map((route) => {
                const name = route.name.toLowerCase();
                const heighestVersion = getHighestVersion(route.versions);
                const versions = route.versions.map((version) => (
                  <Route
                    path={`/${name}/${version.name.toLowerCase()}`}
                    element={<Docs apis={availableDocRoutes} />}
                  />
                ));

                const home = [
                  <Route
                    path={`/${name}`}
                    element={<Navigate to={`/${name}/introduction`} />}
                  />,
                  <Route
                    path={`/${name}/introduction`}
                    element={
                      <SubPage
                        apis={availableDocRoutes}
                        pageName={name}
                        sections={getSections(name)}
                      />
                    }
                  />,
                  <Route
                    path={`/${name}/latest`}
                    element={
                      <Navigate
                        to={`/${name}/${heighestVersion.name.toLowerCase()}`}
                      />
                    }
                  />,
                ];
                return [...home, ...versions];
              })}
            </Routes>
          </div>
        </SideNavProvider>
      </ThemeProvider>
    </Router>
  );
}
