export const METHODS = {
  GET: "get",
  POST: "post",
};

export const INTRODUCTION = "introduction";

export const PAGES = {
  AUTH: "auth",
  SHIPPING: "shipping",
  RETURNS: "returns",
  PICKUP: "pickup",
  TRACKING: "tracking",
};

export const SECURITY = {
  BASIC_AUTH: "basicAuth",
  BEARER_TOKEN: "bearerAccessToken",
  BEARER_REFRESH: "bearerRefreshToken",
};
