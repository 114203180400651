import { Link, useLocation } from "react-router-dom";
import React from "react";
import { useSideNav } from "../context/StateNavContext";
import { capitalizeFirstLetter } from "../helpers/strings";
import { ThemeContext } from "../context/ThemeContext";
import HamburgerIcon from "./Svgs/Hamburger";
import PropTypes from "prop-types";
import DarkModeToggleIcon from "./Svgs/DarkModeToggleIcon";
import DPDLogo from "./Svgs/DPDLogo";

export default function Nav({ showHamburgerMenu }) {
  const { sideNavVis, toggleSideNav } = useSideNav();
  const { pathname } = useLocation();
  const { toggleTheme } = React.useContext(ThemeContext);

  function handleClick() {
    toggleSideNav();
  }

  const renderHamburgerMenu = showHamburgerMenu && (
    <button className="mr-3 lg:hidden block" onClick={handleClick}>
      <HamburgerIcon state={sideNavVis} />
    </button>
  );

  const pageName = decodeURIComponent(pathname.split("/")[1]);
  const versionName = decodeURIComponent(pathname.split("/")[2]);

  return (
    <header className="bg-white relative z-30 ">
      <section className="flex justify-between p-[8px] border-b-2">
        <div className="flex items-center">
          {renderHamburgerMenu}
          <Link to="/">
            <DPDLogo />
          </Link>
          <p className="dpd-nav-text mt-[5px] md:visible invisible">
            {capitalizeFirstLetter(pageName)}{" "}
            {versionName &&
              versionName !== "undefined" &&
              `- ${capitalizeFirstLetter(versionName)}`}
          </p>
        </div>
        <button onClick={toggleTheme}>
          <DarkModeToggleIcon />
        </button>
      </section>
    </header>
  );
}

Nav.propTypes = {
  showHamburgerMenu: PropTypes.bool,
};
