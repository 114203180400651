import { Link } from "react-router-dom";
import Button from "./Buttons";
import ThinkingFace from "./Svgs/ThinkingFace";

export default function Error404Message() {
  return (
    <section className="flex flex-col pt-[56px] px-[50px] pb-[16]">
      <ThinkingFace />
      <h1 className="text-center font-pluto dark:text-dark-text text-[26px] font-[400] tracking-heading text-[#414042] mb-[16px]">
        The page you're looking for can't be found
      </h1>
      <p className="text-center font-pluto text-[14px] dark:text-dark-text font-[400] leading-5 tracking-heading text-[#414042] mb-[32px]">
        please go to the homepage
      </p>
      <Link className="self-center" to="/">
        <Button styleVariant={"redSolid"} className={"rounded-md"}>
          <p className="text-center font-pluto text-base font-[400] tracking-heading">
            Go to homepage
          </p>
        </Button>
      </Link>
    </section>
  );
}
