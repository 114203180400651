import React, { useState } from "react";
import Button from "../Buttons";

export const ParameterInputs = ({
  param,
  index,
  params,
  setEditParams,
  showError,
}) => {
  const [inputList, setInputList] = useState(
    param.value ? [param.value] : [""]
  );

  const handleInputChange = (e) => {
    const updatedParams = [...params];
    updatedParams[index].value = e.target.value;
    setEditParams(updatedParams);
  };

  const handleArrayInputChange = (e, i) => {
    const list = [...inputList];
    list[i] = e.target.value;
    setInputList(list);
    const updatedParams = [...params];
    updatedParams[index].value = list.join(", ");
    setEditParams(updatedParams);
  };

  const handleAddClick = () => {
    setInputList([...inputList, ""]);
  };

  const handleRemoveClick = (i) => {
    const list = [...inputList];
    list.splice(i, 1);
    setInputList(list);
  };

  const borderStyle =
    param.required && !param.value
      ? "border-2 border-red-500"
      : "border-2 border-gray-300";

  if (param.schema.type === "integer") {
    return (
      <input
        type="number"
        className={`p-[10px] min-w-[100px] rounded mt-[8px] dark:bg-[#272729] ${borderStyle}`}
        value={param.value}
        placeholder={param.name}
        onChange={handleInputChange}
        required={param.required}
      />
    );
  } else if (param.schema.type === "array") {
    return (
      <div>
        {inputList.map((x, i) => {
          return (
            <div className="d-flex align-items-center">
              <input
                className={`p-[10px] min-w-[100px] rounded mt-[8px] dark:bg-[#272729] ${borderStyle}`}
                value={x}
                placeholder={param.name}
                onChange={(e) => handleArrayInputChange(e, i)}
                required={param.required}
              />
              {inputList.length !== 1 && (
                <Button
                  className="mx-[4px]"
                  onClick={() => handleRemoveClick(i)}
                >
                  -
                </Button>
              )}
              {inputList.length - 1 === i && (
                <Button className="mx-[4px]" onClick={handleAddClick}>
                  +
                </Button>
              )}
            </div>
          );
        })}
      </div>
    );
  } else if (param.schema.type === "boolean") {
    const borderStyle = param.value
      ? "border-2 border-blue-500"
      : "border-2 border-gray-300";
    return (
      <div
        className={`p-[10px] w-[170px] min-w-[100px] rounded mt-[8px] dark:bg-[#272729] ${borderStyle} cursor-pointer select-none`}
        onClick={() => {
          const updatedParams = [...params];
          updatedParams[index].value = !param.value;
          setEditParams(updatedParams);
        }}
        style={{ textTransform: "uppercase" }}
      >
        {param.value ? "true" : "false"}
      </div>
    );
  } else {
    return (
      <input
        className={`p-[10px] min-w-[100px] rounded mt-[8px] dark:bg-[#272729] ${borderStyle}`}
        value={param.value}
        placeholder={param.name}
        onChange={handleInputChange}
        required={param.required}
      />
    );
  }
};
