import { joinWithHyphen } from "../helpers/strings";
import { Link } from "react-scroll";
import PropTypes from "prop-types";

const OnPageNavItem = ({ items }) => {
  return items.map((item, index) => {
    return (
      <Link
        activeClass="dark:bg-[#272729] bg-[#0000000d] border-l-[3px] border-[#DC0032] pl-[13px] pr-[7px]"
        className="pl-[16px] pr-[7px] py-[8px] block cursor-pointer"
        smooth
        spy
        to={joinWithHyphen(item.summary)}
        key={index}
        offset={-230}
      >
        {item.summary}
      </Link>
    );
  });
};

export default OnPageNavItem;

OnPageNavItem.propTypes = {
  items: PropTypes.array,
};
