import BodyParagraph from "../../components/BodyParagraph";
import Button from "../../components/Buttons";
import Lock from "../../components/Svgs/Lock";

const sections = [
  {
    summary: "Overview",
    body: (
      <>
        <BodyParagraph>
          In order to be able to utilize DPD UK Web services as part of your
          Integration there is a requirement to hold a DPD UK or DPD Local UK
          Account along with username and password. These are the details that
          you will have been provided by your account manager to access the DPD
          UK/ DPD Local website / MyDPD App. Without an account/login it will
          not be possible to access these services.
        </BodyParagraph>
        <BodyParagraph>
          If you hold an account but believe you have not been issued with login
          details or If you require a dedicated login for your API
          implementation please contact your DPD UK account manager and they
          will be able to assist you.
        </BodyParagraph>
        <BodyParagraph>
          Your username and password will be required to access UMS and
          subsequently generate an API Key and Secret to access the API’s .
          Instruction on how to do this can be seen below.
        </BodyParagraph>
        <BodyParagraph>
          <p className="text-[#DC0032]">
            UMS Instructions required for customer to create generate key and
            secret
          </p>
        </BodyParagraph>
        <BodyParagraph>
          For 3rd Party developers working on behalf of a mutual customer, where
          no username and password have yet been provided, please contact DPD to
          request a test username & password / API Key & Secret that can be
          utilised whilst in the testing phase.
        </BodyParagraph>
      </>
    ),
    messageCards: [
      {
        type: "note",
        message:
          "Any test logins/tokens provided must not be shared and only be used within the test process and not used for live shipping once sign off has been obtained.",
      },
    ],
  },
  {
    summary: "Request / Response Format",
    body: (
      <>
        <BodyParagraph>
          DPD UK API’s support JSON as content types and accept types.  The
          destination host supports HTTPS protocol only.  Certain headers are
          required by DPDgroup UK Web services in every request unless stated,
          these are:
        </BodyParagraph>
        <BodyParagraph>
          Authorization (required for all API Requests)
        </BodyParagraph>
        <BodyParagraph>
          Client-Id (required for all API Requests apart from Get Access Token
          API)
        </BodyParagraph>
      </>
    ),
  },
  {
    summary: "Testing Overview",
    body: (
      <>
        <BodyParagraph>
          A formal sign-off must be obtained from the Customer Automation
          Technical Team before live shipping is accepted.  Once we have
          successfully signed off your labels and Insert Shipment JSON (where
          applicable) we will then require one final test pack containing the
          LIVE details (if not already implemented) 
        </BodyParagraph>
        <BodyParagraph>
          DPD may revoke the authorisation to ship if subsequent problems arise
          with data integrity or label quality.Once sign off is obtained It is
          recommended that the first live runs be of small volumes, and
          monitored by both DPD and the client.
        </BodyParagraph>
      </>
    ),
    messageCards: [
      {
        type: "warning",
        message:
          "Please be aware not to use sensitive customer details whilst within the testing process, We highly recommend leaving blank or populating with your own details.",
      },
      {
        type: "note",
        message:
          "When testing your API implementation,  Billing of parcels will not take place unless the parcel / label is seen through the DPD Group network.",
      },
    ],
  },
  {
    summary: "Label Stationery Prerequisites",
    body: (
      <>
        <BodyParagraph>
          When intending to print via Thermal Printer we strongly advise label
          stationary width to measure at least 105 mm. It may not be possible to
          sign off any labels below 102 mm width. Please contact a member of the
          technical team if you have any further queries.
        </BodyParagraph>
      </>
    ),
    messageCards: [
      {
        type: "note",
        message: "Only use of white label stock is permitted.",
      },
    ],
  },
  {
    summary: "Sandbox Environment vs Live Environment",
    body: (
      <>
        <BodyParagraph>
          URL’S provided within this guide relate to the Sandbox environment
          that is a dedicated development only environment provided by ourselves
          that can be used to assist with your development process, this allows
          you to proceed with development work and test if your integration is
          working as expected before switching to the production live
          environment. Live URLs can be found under the Production section once
          your testing has been completed and implementation has been approved.
        </BodyParagraph>
        <BodyParagraph>
          The test URL’S and endpoints are a copy of the live production API's
          so functionality will remain consistent to allow you to test
          effectively and is independent of the live environment. As the sandbox
          environment is independent of the live environment it is required to
          manage API keys independently , so in order to ship via live API’s it
          will be required to create API key for the live environment as these
          are not interchangeable.
        </BodyParagraph>
      </>
    ),
  },
  {
    summary: "Introduction on how to use Portal Test Functionality",
    body: (
      <>
        <BodyParagraph>
          Once you have generated or obtained your API Key/Secret pair it is
          possible to try an API and execute an API Request from the DPD UK API
          Portal Website without the need of additional application/s.
        </BodyParagraph>
        <BodyParagraph>
          <div className="flex justify-between">
            At the top of each API page there is an Authorise button. When
            clicking this button this will display a dialog box and list
            Available authorisations such as basicAuth , bearerAccessToken &
            bearerRefreshToken.
            <Button styleVariant={"redSolid"} className="inline-flex ">
              <Lock className="w-4 h-4 mr-2" />
              Authorise
            </Button>
          </div>
        </BodyParagraph>

        <BodyParagraph>
          This must be populated with the relevant authorisation method for the
          API you wish to use to be able to try the API.
          <br />
          Once details are input and the relevant authorise button is clicked
          the authorization information is set.
        </BodyParagraph>
        <BodyParagraph>
          Each API listed within the specification has a "Try it Out" Button,
          When clicked this will give options to execute or clear and give
          prompts to provide any required parameters or provides an example
          request body that can be amended for certain API’s. (or present the
          authorisation dialog box described above if not already set.)
        </BodyParagraph>
        <BodyParagraph>
          When clicking execute this will send the request to the relevant
          Sandbox endpoint along with any parameters / payload and display a
          copy of the request Curl sent, URL the request was sent to and any
          applicable response.
        </BodyParagraph>
        <BodyParagraph>
          Clicking “Clear” will remove any on screen configured parameters or
          example payload amendments.
        </BodyParagraph>
      </>
    ),
  },
];

export default sections;
