import authenticationSections from "./authentication";
import productionSections from "./production";
import registrationSections from "./registration";

const steps = [
  {
    title: "Registration",
    body: "Before using our APIs, you need to register for a DPD customer account if you don’t already have one.",
    sections: registrationSections,
  },
  {
    title: "Authentication",
    body: "Once you have a DPD / DPD Local customer account, you’ll need to create an Auth token. You need a unique client ID and client secret which will act as your username and password to enable you to request authentication token/s.",
    sections: authenticationSections,
  },
  {
    title: "Select API/Sandbox",
    body: (
      <>
        After you’ve obtained your authentication token/s you can use this to
        integrate with DPD UK Web services and test your application/s using our
        API's in our API sandbox environment.
        <br />
        <br />
        This will assist you in ensuring that your application is working as
        expected.
        <br />
        <br />
        Choose from our Shipping, Tracking, Returns, Pickup and Collections
        APIs.
      </>
    ),
    sections: [],
  },
  {
    title: "Production",
    body: "Following successful testing, our Customer Integration team will work with you to test your label output and approve your move to live via a sign off process.  Once sign off is obtained you can then start shipping live parcels with DPD / DPD Local.",
    sections: productionSections,
  },
];

export default steps;
