import { SECURITY } from "../constants";

export const swaggerTransformer = (data) => {
  const paths = data.paths;
  const schemas = data.components.schemas;
  const urls = Object.keys(paths);

  const getEndpointInfo = (url, paths, schemas) => {
    const swaggerData = paths[url];
    const method = Object.keys(swaggerData)[0];
    const params = swaggerData[method].parameters;

    const security = swaggerData[method]?.security
      ? Object.keys(swaggerData[method].security[0])[0]
      : SECURITY.BEARER_TOKEN;

    const hasRequestBody =
      swaggerData[method].requestBody?.content["application/json"] || null;

    const requestBodyExample = hasRequestBody
      ? swaggerData[method].requestBody?.content["application/json"]?.example
      : null;

    const schemaName = hasRequestBody
      ? hasRequestBody.schema["$ref"].split("/").pop()
      : null;

    const flatternResponses = (res) => {
      const codes = Object.keys(res);

      return codes.map((code) => {
        const hasExampleResponse = res[code]?.content || null;

        return {
          responseCode: code,
          description: res[code].description,
          exampleResponse: hasExampleResponse
            ? res[code]?.content["application/json"]?.example
            : null,
        };
      });
    };

    const responses = flatternResponses(swaggerData[method].responses);

    return {
      urlPath: url,
      method,
      summary: swaggerData[method].summary,
      params,
      description: swaggerData[method].description || null,
      responses,
      requestBodyExample,
      schema: schemaName ? schemas[schemaName] : null,
      authMethod: security,
    };
  };

  const newData = urls.map((url) => getEndpointInfo(url, paths, schemas));

  return newData;
};

export const swaggerAvailableAuth = (doc) => {
  const { securitySchemes } = doc.components;

  if (!securitySchemes) {
    return [];
  }

  const authValues = Object.values(securitySchemes);
  const authKeys = Object.keys(securitySchemes);

  return authValues.map((value, index) => ({
    ...value,
    name: authKeys[index],
  }));
};
