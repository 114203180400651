import JsonBlock from "../JsonBlock";
import CodeBlock from "../Markdown/CodeBlock";
import PropTypes from "prop-types";

const RequestTable = ({
  curlCommand,
  fullURLString,
  requestResponseMessage,
  requestResponseStatus,
}) => {
  return (
    <div className="my-[24px]">
      <h1 className="dpd-heading">Request</h1>
      <div className="mt-[16px] flex">
        <button className="dpd-text px-[8px] py-[16px] border-b-[2px] border-[#DC0032] mb-[-2px]">
          Curl
        </button>
      </div>
      <CodeBlock
        language={"curl"}
        value={curlCommand}
        inline={false}
        withMargin={false}
      />
      <h3 className="dpd-text mt-[16px]">Request URL</h3>
      <CodeBlock inline={false} language={"curl"} value={fullURLString} />
      <h3 className="dpd-text mt-[16px]">Request Response</h3>
      <table className="mt-[16px] dpd-border-table w-full">
        <thead>
          <tr>
            <th className="dpd-text dpd-border-table w-[10%] text-left py-[8px] px-[16px]">
              Code
            </th>
            <th className="dpd-text dpd-border-table text-left py-[8px] px-[16px]">
              Details
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="dpd-border-table dpd-text-code text-center py-[8px] px-[16px]">
              {requestResponseStatus}
            </td>
            <td className="dpd-border-table dpd-text-light py-[8px] px-[16px]">
              <JsonBlock value={requestResponseMessage} />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default RequestTable;

RequestTable.propTypes = {
  curlCommand: PropTypes.string,
  fullURLString: PropTypes.string,
  requestResponseMessage: PropTypes.string,
  requestResponseStatus: PropTypes.string,
};
