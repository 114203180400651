import React from "react";
import { capitalizeFirstLetter } from "../helpers/strings";
import { Link } from "react-router-dom";

const NavCard = ({ navType, navLocation }) => {
  const href =
    navLocation.toLowerCase() === "getting started"
      ? "/getting started"
      : `/getting started/${navLocation.toLowerCase()}`;

  const isNext = navType.toLowerCase() === "next";

  return (
    <div
      className={`flex flex-col items-start max-w-[50%] mx-[10px] mb-[40px] p-4 gap-1 flex-grow border border-gray-300 dark:border-[#444950] rounded ${
        isNext ? "items-end ml-auto" : ""
      }`}
    >
      <p
        className={`text-sm font-medium leading-5 tracking-wide text-gray-600 dark:text-dark-text ${
          isNext ? "text-right" : ""
        }`}
      >
        {navType}
      </p>
      <Link
        to={href}
        className={`text-base font-normal tracking-wide text-red-600 ${
          isNext ? "text-right" : ""
        }`}
      >
        {capitalizeFirstLetter(navLocation)}
      </Link>
    </div>
  );
};

export default NavCard;
