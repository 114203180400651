import ReactMarkdown from "react-markdown";
import Button from "../Buttons";
import { TableMarkdownComponents } from "../Markdown";
import PropTypes from "prop-types";
import { ParameterInputs } from "./ParameterInputs";

const ParametersTable = ({
  params,
  setTryItOut,
  tryItOut,
  authToken,
  toggleModal,
  setEditParams,
  showParamErros,
  resetRequest,
  basicAuth,
}) => {
  const handleOnClick = () => {
    if (!authToken && !basicAuth) {
      return toggleModal();
    }

    if (tryItOut) {
      return resetRequest();
    }

    setTryItOut(true);
  };

  const generateRows = () => {
    return params.map((param, index) => {
      const showError = showParamErros && param.required && param?.value === "";
      return (
        <tr key={index}>
          <td className="dpd-border-table dpd-text-code py-[8px] px-[16px]">
            {param.name}{" "}
            {param.required && <span className="font-bold">*</span>}{" "}
            {param.schema.type}
            <br />
            {`(${param.in})`}
          </td>
          <td className="dpd-border-table dpd-text-light py-[8px] px-[16px]">
            {!tryItOut ? (
              <>
                <ReactMarkdown
                  children={param.description}
                  components={TableMarkdownComponents}
                />
                {param.example}
              </>
            ) : (
              <>
                <ParameterInputs
                  param={param}
                  index={index}
                  params={params}
                  setEditParams={setEditParams}
                  showError={showError}
                />
              </>
            )}
          </td>
        </tr>
      );
    });
  };

  return (
    <div className="my-[24px]">
      <div className="flex">
        <h1 className="dpd-heading">Parameters</h1>
        <Button
          styleVariant={!tryItOut ? "redOutline" : "grayOutline"}
          className={"ml-auto rounded-md"}
          onClick={handleOnClick}
        >
          {!tryItOut ? "Try it out" : "Cancel"}
        </Button>
      </div>
      {params.length > 0 ? (
        <table className="mt-[16px] dpd-border-table w-full">
          <thead>
            <tr>
              <th className="dpd-text dpd-border-table w-[18%] text-left py-[8px] px-[16px]">
                Name
              </th>
              <th className="dpd-text dpd-border-table text-left py-[8px] px-[16px]">
                Description
              </th>
            </tr>
          </thead>
          <tbody>{generateRows(params)}</tbody>
        </table>
      ) : (
        <div className="mt-[16px] dpd-text">No parameters</div>
      )}
    </div>
  );
};

export default ParametersTable;

ParametersTable.propTypes = {
  params: PropTypes.array,
  setTryItOut: PropTypes.func,
  tryItOut: PropTypes.bool,
  authToken: PropTypes.string,
  toggleModal: PropTypes.func,
  setEditParams: PropTypes.func,
  showParamErrors: PropTypes.bool,
  resetRequest: PropTypes.func,
  basicAuth: PropTypes.string,
};
