import React from "react";
import { METHODS } from "../constants";
import OnPageNavItem from "./OnPageNavItem";
import PropTypes from "prop-types";

const OnPageNav = ({ methods }) => {
  const getMethods = methods.filter(({ method }) => method === METHODS.GET);
  const postMethods = methods.filter(({ method }) => method === METHODS.POST);

  return (
    <div className="hidden lg:block w-[250px] 2xl:mr-auto">
      <div className="dpd-border-left sticky top-0">
        <h2 className="dpd-text-small text-[#808285] pt-[24px] pl-[16px] pb-[8px]">
          ON THIS PAGE
        </h2>
        <ul className="list-none dpd-text-light">
          {postMethods.length > 0 && (
            <React.Fragment>
              <div className="pl-[16px] dpd-text bg-[#E1EFE3] dark:bg-[#364F39] py-[8px] text-[#66B553]">
                Post
              </div>
              <OnPageNavItem items={postMethods} />
            </React.Fragment>
          )}
          {getMethods.length > 0 && (
            <React.Fragment>
              <div className="pl-[16px] dpd-text bg-[#E5F0FF] dark:bg-[#142A47] py-[8px] text-[#438DED]">
                Get
              </div>
              <OnPageNavItem items={getMethods} />
            </React.Fragment>
          )}
        </ul>
      </div>
    </div>
  );
};

export default OnPageNav;

OnPageNav.propTypes = {
  methods: PropTypes.array,
};
