import React from "react";
import { ThemeContext } from "../../context/ThemeContext";

const CopyIcon = () => {
  const { theme } = React.useContext(ThemeContext);
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0_223_980"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="16"
        height="16"
      >
        <rect width="16" height="16" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_223_980)">
        <path
          d="M4.33328 13.7436C4.02644 13.7436 3.77024 13.6408 3.56468 13.4353C3.35913 13.2297 3.25635 12.9735 3.25635 12.6667V5.07691H3.92301V12.6667C3.92301 12.7692 3.96575 12.8632 4.05123 12.9487C4.1367 13.0342 4.23071 13.0769 4.33328 13.0769H9.92303V13.7436H4.33328ZM6.33328 11.7436C6.02644 11.7436 5.77024 11.6408 5.56468 11.4352C5.35913 11.2297 5.25635 10.9735 5.25635 10.6667V3.48716C5.25635 3.18033 5.35913 2.92414 5.56468 2.71858C5.77024 2.51303 6.02644 2.41025 6.33328 2.41025H11.5128C11.8196 2.41025 12.0758 2.51303 12.2814 2.71858C12.4869 2.92414 12.5897 3.18033 12.5897 3.48716V10.6667C12.5897 10.9735 12.4869 11.2297 12.2814 11.4352C12.0758 11.6408 11.8196 11.7436 11.5128 11.7436H6.33328Z"
          fill={theme === "dark" ? "white" : "black"}
          fillOpacity="0.3"
        />
      </g>
    </svg>
  );
};

export default CopyIcon;
