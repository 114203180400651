import Nav from "../components/Nav";
import Error404Message from "../components/Error404Message";
import SideNav from "../components/SideNav";
import { useSideNav } from "../context/StateNavContext";

const PageNotFound = ({ apis }) => {
  const { sideNavVis, toggleSideNav } = useSideNav();
  const closeNav = () => {
    toggleSideNav();
  };
  return (
    <div>
      <Nav
        pageName={"404"}
        showHamburgerMenu={true}
        className="absolute z-40"
      />

      <div className="grid grid-cols-3 gap-4">
        <SideNav routes={apis} />
        <div className="col-span-2">
          {sideNavVis && (
            <div
              onClick={closeNav}
              className="bg-grey-out-of-focus fixed inset-0 z-10 w-screen h-screen"
            />
          )}
          <div className="lg:px-[24px]">
            <div className={`lg:pr-[35%] mx-auto lg:mx-0`}>
              <Error404Message />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PageNotFound;
